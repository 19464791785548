import React, { useState } from 'react';
import './services.css';
import './instruction.css';
import './comments.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { translations } from './translation';



const Comments = ({ setActiveMenu }) => {
    const language = useSelector(selectLanguage)

    return (
//         <div className='cntr_instruction' style={{ marginTop: '140px'}}>
//             <div className="services_title_cntr">
//                 <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label>
//                 <div className='title1_line' style={{ marginBottom: '20px'}}></div>
//                 <label className='title1_wellcome2' >
//                     Τι εχουν πει<br/> για μας
//                 </label>
//             </div>

//             <div className='row_comments' style={{ marginTop: '60px'}}>
//                 <div className='cntr_comments_item'>
//                     <div className='title_comments_item'>Georgia Karamolegkou</div>
//                     <div className='text_comments_item'>
//                     Άριστος επαγγελματίας με κατάρτιση, εμπειρία και ήθος. Αδιαμφισβήτητο χάρισμα η ανθρωπιά και η ειλικρίνεια. Αξιόπιστος, με συνέπεια και εξαιρετικά αφοσιωμένος στην υπόθεση που είχε αναλάβει εκ μέρους μας. Υπήρξε μια άψογη συνεργασία.
//                     Εν κατακλείδι, αυτό που θέλω να εκφράσω είναι ένα ΕΥΧΑΡΣΤΩ και συνεχίστε με τον ίδιο σεβασμό να ασκείται το λειτούργημα της δικηγορίας. 
//                     </div>
//                 </div>
//                 <div className='cntr_comments_item'>
//                     <div className='title_comments_item'>ΝΙΚΟΛΑΟΣ ΜΕΞΑΣ</div>
//                     <div className='text_comments_item'>
//                     ΕΞΑΙΡΕΤΟΣ ΑΝΘΡΩΠΟΣ ΚΑΙ ΑΡΙΣΤΟΣ ΕΠΙΣΤΗΜΟΝΑΣ
// Για μένα είναι πρώτα από όλα άνθρωπος με ήθος και αξιοπρέπεια. Είναι πολύ ευγενικός, χαμογελαστός και αισθάνεσαι οικειότητα από το πρώτο λεπτό.
// Ως δικηγόρος είναι ένας άριστος επιστήμονας, με υψηλή νομική κατάρτιση και σεβασμό στον πελάτη του, ακούει προσεκτικά και απαντά σε όλες τις ερωτήσεις.
// Τον συνιστώ ανεπιφύλακτα.
//                     </div>
//                 </div>
//                 <div className='cntr_comments_item'>
//                     <div className='title_comments_item'>ΣΩΤΗΡΗΣ ΤΡΙΒΕΛΛΑΣ</div>
//                     <div className='text_comments_item'>
//                     Προσωπική μου άποψη ύστερα από πολλά χρόνια συνεργασίας μαζί του απλά ικανός για τα πάντα. Τέρας ηθικής και τιμιότητας!!!
//                     </div>
//                 </div>
//             </div>
//         </div>

<div className='cntr_instruction' style={{ marginTop: '140px'}}>
    <div className="services_title_cntr">
        <label className='title1_wellcome'>
            {translations[language].services}
        </label>
        <div className='title1_line' style={{ marginBottom: '20px'}}></div>
        <label className='title1_wellcome2'>
            {translations[language].whatTheySay}<br/> {translations[language].aboutUs}
        </label>
    </div>

    <div className='row_comments' style={{ marginTop: '60px'}}>
        {translations[language].comments.map((comment, index) => (
            <div key={index} className='cntr_comments_item'>
                <div className='title_comments_item'>{comment.name}</div>
                <div className='text_comments_item'>{comment.text}</div>
            </div>
        ))}
    </div>
    <div className='row_comments_mob' style={{ marginTop: '60px'}}>
        {translations[language].comments.slice(0, 1).map((comment, index) => (
            <div key={index} className='cntr_comments_item'>
                <div className='title_comments_item'>{comment.name}</div>
                <div className='text_comments_item'>{comment.text}</div>
            </div>
        ))}
    </div>
</div>

    );
};

export default Comments;
