import { url_back } from "../../static/vars";

async function getPayLink(email) {
    try {
        const response = await fetch(url_back + '/paylink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const result = await response.json();
            console.log('result', result);

            return result;
        } else {
            console.log('Error sending question');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

const sendRegister = async (email, phone, name, surname) => {
    // Отправка запроса на бэкэнд для регистрации
    try {
        const response = await fetch(url_back + '/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, phone, name, surname })
        });

        if (response.ok) {
            // alert('Вы зарегистрированы');
            const result = await response.json();
            console.log('result', result);

            return result;
        } else {
            // alert('Wrong data');
            console.log('Error sending question');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
};

const handleLogin = async (email, password) => {
    // Отправка запроса на бэкэнд для авторизации
    const response = await fetch(url_back + '/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });

    if (response.ok) {
      const data = await response.json();
      console.log('login', data)
      return data
    } else {
        return false
      alert('Ошибка авторизации');
    }
  };

export { getPayLink, sendRegister, handleLogin };
