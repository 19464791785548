// LawyerForm.js
import React, { useState } from 'react';
import './lawyerForm.css';
import { sendLawyerQuestion } from './apiLawyer'; // Предполагаем, что эта функция реализована для отправки вопросов адвокату

const LawyerForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [showRes, setShowRes] = useState(false);
    const [response, setResponse] = useState('');
    const [consent, setConsent] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const result = await sendLawyerQuestion(name, email, phone, question);
        if (result) {
            setResponse(result.response);
            setLoading(false);
            setShowRes(true)
        }
    };

    const handleNewAnswer = () => {
        setEmail('')
        setName('')
        setQuestion('')
        setPhone('')
        setShowRes(false)
    }

    return (
        <div className="lawyer-form-container">
            <h2>Επικοινωνία με δικηγόρο</h2>
            <div className='lawyer-form-container-fields'>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Όνομα"
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Ηλεκτρονικό ταχυδρομείο"
                />
                <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Τηλέφωνο"
                />
                <textarea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Παρακαλώ εισάγετε την ερώτησή σας εδώ..."
                />
                <div className="consent-container">
                    <input
                        type="checkbox"
                        checked={consent}
                        onChange={() => setConsent(!consent)}
                    />
                    <label>Δίνω συγκατάθεση για την επεξεργασία των δεδομένων</label>
                </div>
            </div>
            {loading ? (
                <p>Αναμονή για απάντηση...</p>
            ) :
                showRes ? (
                    <>
                        <div>Η ερώτησή σας έχει σταλεί στον δικηγόρο</div>
                        <button style={{ marginTop: '20px'}} onClick={handleNewAnswer}>Νέα ερώτηση</button>
                    </>
                ) :
                    (
                        <button onClick={handleSubmit}>Αποστολή</button>// send
                    )}
            {response && <p>{response}</p>}
        </div>
    );
};

export default LawyerForm;
