// home.js
import React, { useState } from 'react';
import './home.css';
import logo_menu from '../../static/img/logo_menu.png';
import OnlineConsultant from '../consultant/consultant';
import LawyerForm from '../lawyer/LawyerForm';
import Profile from '../profile/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectLanguage, selectShowModal, selectStatus, setEmail } from './homeSlice';
import Login from '../login/login';
import Register from '../login/register';
import MainPage from '../mainPage/mainPage';
import burger from '../../static/img/burger-bar.png';
import AboutUs from '../mainPage/aboutUs';
import { svg_out, svg_burger } from '../../static/svg';
import LanguageSelector from './languageSelector';
import { translations } from './translation';
import Instructions from '../mainPage/instruction';
import ContactUs from './contactUs';
import FeedBack from './wellCome';
import Services from './services';
import Advantage from './advantage';
import Booking from './booking';
import Instruction from './instruction';
import Comments from './comments';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';
import NavMenu from '../navMenu/navMenu';
import ModalData from '../mainPage/modalData';

function Home() {
    const dispatch = useDispatch()
    const email = useSelector(selectEmail);
    const [activeMenu, setActiveMenu] = useState('main');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const language = useSelector(selectLanguage)
    const navigate = useNavigate()
    const showModal = useSelector(selectShowModal)

    const handleMenuClick = (menu) => {
        navigate('/')
    };

    const goToServicesPage = () => {
        navigate('/services'); // Программный переход на страницу /about
    };

    const goToInstructionsPage = () => {
        navigate('/instructions'); // Программный переход на страницу /about
    };

      

    return (

        <div className="home-container">
            <div className='cntr_line'>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>
            <div className='cntr_line2'>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>

            {/* <nav className="nav-menu" style={{ position: 'absolute', top: "0px", height: '0px', zIndex: 1 }}>
                <div style={{ width: '64px', marginTop: '21px' }}>
                    <img src={logo_menu} alt="Logo" className="logo_menu nav-item" />
                </div>
                <div style={{ marginTop: '31px' }} className='nav-item'>
                    <LanguageSelector />
                </div>

                <div className='cntr_menu'>

                    <span className="nav-item" onClick={() => handleMenuClick('main')}>{translations[language].home}</span>
                    <span className="nav-item" onClick={() => handleMenuClick('we')}>{translations[language].aboutUs}</span>
                    <span className="nav-item" onClick={goToServicesPage}>ΥΠΗΡΕΣΙΕΣ</span>

                    <span className="nav-item" onClick={goToInstructionsPage}>{translations[language].instructions}</span>
                    <span className="nav-item" onClick={() => { }}>{translations[language].forLawyers}</span>

                </div>

                <div className='nav-item txt_cap_under' style={{marginTop: '31px', marginLeft: '270px'}}>
                    Κράτηση 24/7
                </div>

                {email !== '' &&
                    <div onClick={() => dispatch(setEmail(''))} className='svg_out'>
                        {svg_out}
                    </div>
                }
            </nav> */}

            {/* <nav className="nav-menu-mobile" style={{ position: 'absolute', top: "20px", height: '0px', zIndex: 1 }}>
                <div style={{ width: '150px' }}>
                    <img src={logo_menu} alt="Logo" className="logo nav-item" />
                </div>

                

                <span className="burger-menu-home" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    
                    {svg_burger}
                </span>
                <div className='nav-item' style={{  }}>
                    <LanguageSelector />
                </div>
                <div className='nav-item txt_cap_under' style={{ width: 'fit-content', position: 'absolute', left: '140px', top: '21px'}}>
                    Κράτηση 24/7
                </div>

                {isMenuOpen && (
                    <div className="mobile-menu">
                        <span className="nav-item" onClick={() => handleMenuClick('main')}>{translations[language].home}</span>
                        <span className="nav-item" onClick={() => handleMenuClick('we')}>{translations[language].aboutUs}</span>
                        <span className="nav-item" onClick={() => handleMenuClick('instructions')}>{translations[language].instructions}</span>
                        <span className="nav-item" onClick={() => { }}>{translations[language].forLawyers}</span>
                    </div>
                )}
            </nav> */}

            <NavMenu/>

            {activeMenu === 'main' && <MainPage handleMenuClick={handleMenuClick} setActiveMenu={setActiveMenu} />}
            {activeMenu === 'we' && <AboutUs />}
            {activeMenu === 'ai' && <OnlineConsultant handleMenuClick={handleMenuClick}/>}
            {activeMenu === 'lawyer' && <LawyerForm />}
            {activeMenu === 'profile' && <Profile />}
            {activeMenu === 'login' && <Login setActiveMenu={setActiveMenu} />}
            {activeMenu === 'reg' && <Register setActiveMenu={setActiveMenu} />}
            {activeMenu === 'instructions' && <Instructions />}

            {/* <ContactUs/> */}
            <FeedBack />
            <Services setActiveMenu={setActiveMenu}/>
            <Advantage/>
            <Booking/>
            <Instruction/>
            <Comments/>
            <Footer handleMenuClick={handleMenuClick}/>

            <ModalData
                show={showModal}
                // handleClose={handleModalClose}
                // handleSubmit={handleRegisterAndBuy}
            />
        </div>

    );
}

export default Home;
