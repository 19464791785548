// Profile.js
import React, { useState } from 'react';
import './profile.css';
import { getPayLink } from './apiProfile';
import { useSelector } from 'react-redux';
import { selectDatetime, selectEmail, selectStatus } from '../home/homeSlice';

function Profile() {
    const email = useSelector(selectEmail)
    const status = useSelector(selectStatus)
    const datetime = useSelector(selectDatetime)

    const handleBuySubscription = async () => {
        // Logic for buying subscription, this is just a placeholder
        const paylink = await getPayLink(email)
        console.log('paylink', paylink)
        window.open(paylink.paymentLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="profile-container">
            <h2>Προφίλ</h2>
            <div className="profile-item">
                <label>Email: </label>
                <span>{email}</span>
            </div>
            <div className="profile-item">
                <label>Подписка:</label>
                <span style={{marginLeft: '20px'}}>
                    {status === 'subscribe' ? `Действует до ${datetime}` : 'Нет'}
                </span>
            </div>
            {status !== 'subscribe' && (
                <button onClick={handleBuySubscription}>Купить подписку</button>
            )}
        </div>
    );
}

export default Profile;
