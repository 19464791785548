import React, { useEffect, useState } from 'react';
import './termsUse.css';



import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';
import ToggleInstruction from './toggleInstruction';
import { textDict2 } from './dic';



const Questions = ({ setActiveMenu }) => {

    const language = useSelector(selectLanguage)

    const text1 = `Απάντηση: Η σελίδα δικηγόρος online έχει φτιαχτεί για να σας κάνει τη ζωή πιο εύκολη και στη δυσκολία  να βρίσκετε λύσεις οικονομικά και άμεσα. Έχουμε διάφορες επιλογές στη σελίδα μας για να βρείτε την κατάλληλη λύση για σας. Για πιο άμεση απάντηση λειτουργεί 24/7 το chat bot μας, και αν επιθυμείτε άμεση συνάντηση με τους δικηγόρους μας μπορείτε να επιλέξετε το κουμπί booking online και μέσα στις 24 h εγγυημένα θα έχετε online συνάντηση με ένα από έμπειρους δικηγόρους της πλατφόρμας μας.`

    return (
        <div className='chat_main_cntr'>
            
            <div className="services_title_cntr" style={{ marginBottom: '40px' }}>
                {/* <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label> */}
                <div className='title1_line' style={{ marginTop: '140px' }}></div>
                <label className='title1_wellcome2'>
                   { textDict2[language].titleOfList}
                </label>
            </div>

            <ToggleInstruction text1={textDict2[language].legalAdvice} text2={textDict2[language].legalAdviceDetails} />
            <ToggleInstruction text1={textDict2[language].familyLaw} text2={textDict2[language].familyLawDetails} />
            <ToggleInstruction text1={textDict2[language].criminalLaw} text2={textDict2[language].criminalLawDetails} />
            <ToggleInstruction text1={textDict2[language].laborLaw} text2={textDict2[language].laborLawDetails} />
            <ToggleInstruction text1={textDict2[language].civilLaw} text2={textDict2[language].civilLawDetails} />
            <ToggleInstruction text1={textDict2[language].personalDataLaw} text2={textDict2[language].personalDataLawDetails} />
            <ToggleInstruction text1={textDict2[language].debtReliefLaw} text2={textDict2[language].debtReliefLawDetails} />
            <ToggleInstruction text1={textDict2[language].taxLaw} text2={textDict2[language].taxLawDetails} />
            <ToggleInstruction text1={textDict2[language].realEstateLaw} text2={textDict2[language].realEstateLawDetails} />
            <ToggleInstruction text1={textDict2[language].enforcementLaw} text2={textDict2[language].enforcementLawDetails} />
            <ToggleInstruction text1={textDict2[language].expropriationLaw} text2={textDict2[language].expropriationLawDetails} />
            <ToggleInstruction text1={textDict2[language].trafficAccidents} text2={textDict2[language].trafficAccidentsDetails} />
            <ToggleInstruction text1={textDict2[language].medicalMalpractice} text2={textDict2[language].medicalMalpracticeDetails} />
            <ToggleInstruction text1={textDict2[language].investmentRefunds} text2={textDict2[language].investmentRefundsDetails} />
            <ToggleInstruction text1={textDict2[language].chatbotServices} text2={textDict2[language].chatbotServicesDetails} />
            <ToggleInstruction text1={textDict2[language].onlineAppointment} text2={textDict2[language].onlineAppointmentDetails} />
        </div>

    );
};

export default Questions;
