import React, { useEffect, useState } from 'react';
import './services.css';
import wellcome_img from '../../static/img/wellcome.png';

import service_img1 from '../../static/img/service_img1.png'
import service_img2 from '../../static/img/service_img2.png'
import service_img3 from '../../static/img/service_img3.png'

import service_img4 from '../../static/img/service_img4.png'
import service_img5 from '../../static/img/service_img5.png'

import service_img_mob1 from '../../static/img/service_img_mob1.png'
import service_img_mob2 from '../../static/img/service_img_mob2.png'
import service_img_mob3 from '../../static/img/service_img_mob3.png'

import service_img_mob4 from '../../static/img/service_img_mob4.png'
import service_img_mob5 from '../../static/img/service_img_mob5.png'

import { translations } from './translation';
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { handleLogin } from '../login/apiLogin';


const Services = ({ setActiveMenu }) => {
    const [email, setEmailInput] = useState('');
    const [password, setPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const language = useSelector(selectLanguage)

    const handlePressLogin = () => {
        handleLogin(email, password)
        console.log('handleLogin')
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    useEffect(() => {
        setEmailInput('')
    }, []);

    const texts = [
        {
            title: translations[language].legalAdviceTitle,
            text: translations[language].legalAdviceText
        },
        {
            title: translations[language].familyLawTitle,
            text: translations[language].familyLawText
        },
        {
            title: translations[language].criminalLawTitle,
            text: translations[language].criminalLawText
        },
        {
            title: translations[language].laborLawTitle,
            text: translations[language].laborLawText
        },
        {
            title: translations[language].civilLawTitle,
            text: translations[language].civilLawText
        }
    ];


    return (
        <div className='services_cntr'>
            {/* <div className="services_title_cntr">
                <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label>
                <div className='title1_line'></div>
                <label className='title1_wellcome2'>
                    ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ
                </label>
            </div> */}
            <div className="services_title_cntr">
                <label className='title1_wellcome'>{translations[language].servicesTitle}</label>
                <div className='title1_line'></div>
                <label className='title1_wellcome2'>{translations[language].ourServicesTitle}</label>
            </div>


            <div className='services_row' style={{ marginTop: '60px' }}>
                <div style={{ width: '420px', position: 'relative' }}>
                    <img style={{ width: '420px' }} src={service_img1} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[0].title}</div>
                        <div className='service_text'>{texts[0].text}</div>
                    </div>
                </div>
                <div style={{ width: '420px', position: 'relative' }}>
                    <img style={{ width: '420px' }} src={service_img2} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[1].title}</div>
                        <div className='service_text'>{texts[1].text}</div>
                    </div>
                </div>
                <div style={{ width: '420px', position: 'relative' }}>
                    <img style={{ width: '420px' }} src={service_img3} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[2].title}</div>
                        <div className='service_text'>{texts[2].text}</div>
                    </div>
                </div>
            </div>


            <div className='services_row' style={{ marginTop: '20px' }}>
                <div style={{ width: '420px', position: 'relative' }}>
                    <img style={{ width: '420px' }} src={service_img4} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[3].title}</div>
                        <div className='service_text'>{texts[3].text}</div>
                    </div>
                </div>
                <div style={{ width: '420px', position: 'relative' }}>
                    <img style={{ width: '420px' }} src={service_img5} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[4].title}</div>
                        <div className='service_text'>{texts[4].text}</div>
                    </div>
                </div>
                <div style={{ width: '420px', height: '520px' }}>
                    <div className='cntr_login_widget'>
                        <label className='login_widget_title'>{translations[language].onlineLawyerChat}</label>
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                // WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                                marginTop: '20px',
                                color: 'white'
                            }}
                            className='login_widget_input'
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                        <input
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='login_widget_button' onClick={handlePressLogin}>{translations[language].login}</button>
                        <p style={{ textDecoration: 'underline' }} onClick={() => setActiveMenu('reg')}>{translations[language].registration}</p>
                        {/* <label className='login_widget_footer_text'>{translations[language].dataProcessingConsent}</label> */}

                        <div className='lawyer_bottom_cntr' style={{justifyContent: 'center', gap: '24px', width: '360px'}}>
                            <div className='modal_checkbox'>
                                <input
                                    className='modal_checkbox'
                                    type='checkbox'
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className='lawyer_bottom_text' style={{width: 'fit-content'}}>
                                {translations[language].modal_consentText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='services_cntr_img_mob'>
                {/* <img style={{ width: '320px' }} src={service_img_mob1} alt='' /> */}

                <div style={{ width: '320px', position: 'relative' }}>
                    <img style={{ width: '320px' }} src={service_img_mob1} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[0].title}</div>
                        <div className='service_text'>{texts[0].text}</div>
                    </div>
                </div>




                {/* <img style={{ width: '320px' }} src={service_img_mob2} alt='' /> */}
                <div style={{ width: '320px', position: 'relative' }}>
                    <img style={{ width: '320px' }} src={service_img_mob2} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[1].title}</div>
                        <div className='service_text'>{texts[1].text}</div>
                    </div>
                </div>
                {/* <img style={{ width: '320px' }} src={service_img_mob3} alt='' /> */}
                <div style={{ width: '320px', position: 'relative' }}>
                    <img style={{ width: '320px' }} src={service_img_mob3} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[2].title}</div>
                        <div className='service_text'>{texts[2].text}</div>
                    </div>
                </div>

                {/* <img style={{ width: '320px' }} src={service_img_mob4} alt='' /> */}
                <div style={{ width: '320px', position: 'relative' }}>
                    <img style={{ width: '320px' }} src={service_img_mob4} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[3].title}</div>
                        <div className='service_text'>{texts[3].text}</div>
                    </div>
                </div>

                {/* <img style={{ width: '320px' }} src={service_img_mob5} alt='' /> */}
                <div style={{ width: '320px', position: 'relative' }}>
                    <img style={{ width: '320px' }} src={service_img_mob5} alt='' />
                    <div className='cntr_service_text'>
                        <div className='service_title'>{texts[4].title}</div>
                        <div className='service_text'>{texts[4].text}</div>
                    </div>
                </div>

                <div style={{ width: '320px', height: '520px' }}>
                    <div className='cntr_login_widget'>
                        <label className='login_widget_title'>{translations[language].onlineLawyerChat}</label>
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                                marginTop: '20px',
                                color: 'white'
                            }}
                            className='login_widget_input'
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                        <input
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='login_widget_button' onClick={handlePressLogin}>{translations[language].login}</button>
                        <p style={{ textDecoration: 'underline' }} onClick={() => { }}>{translations[language].registration}</p>
                        {/* <label className='login_widget_footer_text'>{translations[language].dataProcessingConsent}</label> */}
                        <div className='lawyer_bottom_cntr'>
                            <div className='modal_checkbox'>
                                <input
                                    className='modal_checkbox'
                                    type='checkbox'
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className='modal_bottom_text' style={{width: '270px', margin: 0}}>
                                {translations[language].modal_consentText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className='services_button' onClick={() => { }}>{translations[language].otherServices}</button>
        </div>

    );
};

export default Services;
