import { url_back } from "../../static/vars";

async function sendLawyerQuestion(name, email, phone, question) {
    
    try {
        const response = await fetch(url_back + '/lawyer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, email, phone, question }),
        });

        alert('question sent')

        if (response.ok) {
            const result = await response.json();
            console.log('answer', result);
            
            return result;
        } else {
            console.log('Error sending question');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

export { sendLawyerQuestion };
