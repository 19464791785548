import React, { useState } from 'react';
// import './services.css';
import './lawyersPage.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';
import { translations } from '../home/translation';



const LawyersBlock = ({ setActiveMenu }) => {
    const language = useSelector(selectLanguage)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [regnum, setRegnum] = useState('');
    const [association, setAssociation] = useState('');
    const [specialization, setSpecialization] = useState('');
    const [isChecked, setIsChecked] = useState(false);


    const [question, setQuestion] = useState('');

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <div className='cntr_booking' style={{ marginTop: '140px' }}>
            <div className='subcntr_lawyer_block'>
                <div style={{ marginTop: '40px' }} className='row_input_booking'>
                    <input
                        style={{
                            WebkitBoxShadow: '0 0 0 1000px transparent inset',
                            boxShadow: '0 0 0 1000px transparent inset',
                            WebkitTextFillColor: 'inherit',
                            backgroundColor: 'transparent',
                        }}
                        className='login_widget_input'
                        placeholder={translations[language].lawyers_nameSurnamePlaceholder}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        className='login_widget_input'
                        placeholder={translations[language].lawyers_professionalAddressPlaceholder}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className='row_input_booking'>
                    <input
                        style={{
                            WebkitBoxShadow: '0 0 0 1000px transparent inset',
                            boxShadow: '0 0 0 1000px transparent inset',
                            WebkitTextFillColor: 'inherit',
                            backgroundColor: 'transparent',
                        }}
                        className='login_widget_input'
                        type="email"
                        placeholder={translations[language].lawyers_registrationNumberPlaceholder}
                        value={regnum}
                        onChange={(e) => setRegnum(e.target.value)}
                    />
                    <input
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_associationPlaceholder}
                        value={association}
                        onChange={(e) => setAssociation(e.target.value)}
                    />
                </div>

                <div className='row_input_booking'>
                    <input
                        style={{
                            WebkitBoxShadow: '0 0 0 1000px transparent inset',
                            boxShadow: '0 0 0 1000px transparent inset',
                            WebkitTextFillColor: 'inherit',
                            backgroundColor: 'transparent',
                        }}
                        className='login_widget_input'
                        type="email"
                        placeholder={translations[language].lawyers_emailPlaceholder}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_phonePlaceholder}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>

                <div className='cntr_booking_input_mob'>
                    <input
                        style={{ marginTop: '60px' }}
                        className='booking_widget_input'
                        type="phone"
                        placeholder={translations[language].lawyers_specializationPlaceholder}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                        className='booking_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_specializationPlaceholder}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                        className='booking_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_specializationPlaceholder}
                        value={specialization}
                        onChange={(e) => setSpecialization(e.target.value)}
                    />
                    <input
                        style={{
                            WebkitBoxShadow: '0 0 0 1000px transparent inset',
                            boxShadow: '0 0 0 1000px transparent inset',
                            WebkitTextFillColor: 'inherit',
                            backgroundColor: 'transparent',
                        }}
                        className='login_widget_input'
                        type="email"
                        placeholder={translations[language].lawyers_emailPlaceholder}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_specializationPlaceholder}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                        style={{ width: '740px', height: '45px' }}
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_specializationPlaceholder}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <input
                        style={{ width: '740px', height: '45px' }}
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].lawyers_experiencePlaceholder}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='btn_lawyers'>
                        {translations[language].lawyers_continueButton}
                    </div>
                </div>

                {/* <div className='subtitle_boking'>
                    {translations[language].lawyers_consentText}
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='lawyer_bottom_cntr'>
                        <div className='modal_checkbox'>
                            <input
                                className='modal_checkbox'
                                type='checkbox'
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className='lawyer_bottom_text'>
                            {translations[language].modal_consentText}
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default LawyersBlock;
