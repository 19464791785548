export const textDict = {
    'Russian': {
        whowe: 'Кто мы',
        whoWeMainText: 'Кто мы',  // Поле из вашего кода
        whoWeSubMainText: '', // Поле из вашего кода
        whoWeSubMainText2: 'Юридические услуги везде и всегда. 24/7 к вашим услугам и готовы помочь решить ваши проблемы.', // Пример текста, можете заменить
    },
    'English': {
        whowe: 'Who we are',
        whoWeMainText: 'Who we are',  // Поле из вашего кода
        whoWeSubMainText: '',  // Поле из вашего кода
        whoWeSubMainText2: 'Legal Services Everywhere, Anytime. 24/7 at Your Service and Ready to Help Solve Your Problems.', // Пример текста, можете заменить
    },
    'Greek': {
        whowe: 'Ποιοι είμαστε',
        whoWeMainText: 'Ποιοι είμαστε',  // Поле из вашего кода
        whoWeSubMainText: '', // Поле из вашего кода
        whoWeSubMainText2: 'Νομικές Υπηρεσίες Παντού, Ανά Πάσα Στιγμή. 24/7 στη Διάθεσή σας και Έτοιμοι να Λύσουμε τα Προβλήματά σας.', // Пример текста, можете заменить
    }
};

export const textDict2 = {
    'Russian': {
        titleOfList: 'Наши услуги',
        legalAdvice: 'Юридические консультации 24/7',
        legalAdviceDetails: 'Наши юристы доступны круглосуточно для ответа на любые ваши юридические вопросы, от срочных до повседневных.',
        familyLaw: 'Семейное право',
        familyLawDetails: 'Мы оказываем поддержку в делах, касающихся развода, опеки над детьми, алиментов и других семейных вопросов.',
        criminalLaw: 'Уголовное право',
        criminalLawDetails: 'Специализированная защита и консультации в уголовных делах, гарантируя защиту ваших прав.',
        laborLaw: 'Трудовое право',
        laborLawDetails: 'Помощь с вопросами, такими как трудовые споры, увольнения, заработная плата и другие трудовые права.',
        civilLaw: 'Гражданское право',
        civilLawDetails: 'Мы охватываем широкий спектр гражданских дел различной сложности.',
        personalDataLaw: 'Закон о персональных данных',
        personalDataLawDetails: 'Нарушение частной жизни — это чума нашего времени. Защита существует, если вы знаете, где ее искать.',
        debtReliefLaw: 'Закон о банкротстве',
        debtReliefLawDetails: 'Перегруженность долгами — это главный источник неуверенности. Правильный план и направление всегда дают еще одну возможность.',
        taxLaw: 'Налоговое право',
        taxLawDetails: 'Взаимоотношения государства и граждан в Греции печально известны своей враждебностью. Только обладая знаниями и правильным руководством, можно избежать непомерных штрафов и наказаний.',
        realEstateLaw: 'Закон о недвижимости',
        realEstateLawDetails: 'Аренда, передача, вопросы градостроительства, распределение имущества среди наследников — это лишь некоторые из вопросов, с которыми сталкиваются собственники недвижимости в Греции.',
        enforcementLaw: 'Закон о предотвращении взыскания',
        enforcementLawDetails: 'Своевременная остановка процедуры взыскания является лучшим переговорным инструментом для дальнейших обсуждений с кредиторами.',
        expropriationLaw: 'Закон о принудительном отчуждении',
        expropriationLawDetails: 'Вы потеряли свою собственность в пользу государства и не получили компенсацию? Мы занимаемся процессом обеспечения выплаты или даже возврата вашей собственности.',
        trafficAccidents: 'Дорожно-транспортные происшествия',
        trafficAccidentsDetails: 'Взаимодействие со страховыми компаниями может быть сложным. Получите компенсацию, которую вы заслуживаете.',
        medicalMalpractice: 'Медицинская халатность и небрежность в системе здравоохранения',
        medicalMalpracticeDetails: '',
        investmentRefunds: 'Возвраты по инвестиционным законам и программам ЕС',
        investmentRefundsDetails: 'Государство часто требует возврата всей суммы инвестиций за незначительные нарушения. Мы занимаемся процессом для предотвращения такой несправедливости.',
        chatbotServices: 'Услуги чат-бота',
        chatbotServicesDetails: 'Наш интеллектуальный чат-бот доступен для ответа на все ваши юридические вопросы на любом языке. Эта услуга платная.',
        onlineAppointment: 'Онлайн-запись к юристу',
        onlineAppointmentDetails: 'Запишитесь на прием к юристу в течение 24 часов и получите необходимую поддержку. Наши услуги платные.'
    },
    'English': {
        titleOfList: 'Our Services',
        legalAdvice: 'Legal Advice 24/7',
        legalAdviceDetails: 'Our lawyers are available around the clock to answer all your legal questions, from urgent issues to everyday legal concerns.',
        familyLaw: 'Family Law',
        familyLawDetails: 'We provide support in matters of divorce, child custody, alimony, and other family law cases.',
        criminalLaw: 'Criminal Law',
        criminalLawDetails: 'Specialized defense and consultation in criminal cases, ensuring your rights are protected.',
        laborLaw: 'Labor Law',
        laborLawDetails: 'Assistance with issues such as employment disputes, dismissals, wages, and other labor rights.',
        civilLaw: 'Civil Law',
        civilLawDetails: 'We cover a wide range of civil cases, of varying degrees of difficulty.',
        personalDataLaw: 'Personal Data Law',
        personalDataLawDetails: 'The violation of individual privacy is a plague of our time. There is protection, as long as you know where to find it.',
        debtReliefLaw: 'Debt Relief Law for Individuals and Businesses',
        debtReliefLawDetails: 'Over-indebtedness is the number one issue causing uncertainty. With the right plan and direction, there’s always another option.',
        taxLaw: 'Tax Law',
        taxLawDetails: 'The relationship between the state and citizens in Greece is notoriously hostile. Only with expertise and proper guidance can you avoid unbearable fines and penalties from tax authorities.',
        realEstateLaw: 'Real Estate Law',
        realEstateLawDetails: 'Leases, transfers, urban planning issues, distribution of property among heirs—these are just a few of the everyday issues faced by property owners in Greece.',
        enforcementLaw: 'Enforcement and Foreclosure Prevention Law',
        enforcementLawDetails: 'Stopping foreclosure proceedings in time is the best tool for negotiating with creditors.',
        expropriationLaw: 'Compulsory Expropriation Law',
        expropriationLawDetails: 'Have you lost your property to the state and haven’t been compensated? We handle the entire process of securing your payment.',
        trafficAccidents: 'Traffic Accidents',
        trafficAccidentsDetails: 'Dealing with insurance companies can be tough. Get the compensation you deserve.',
        medicalMalpractice: 'Medical Malpractice and Healthcare System Negligence',
        medicalMalpracticeDetails: '',
        investmentRefunds: 'Refunds from Investment Laws and EU Programs',
        investmentRefundsDetails: 'The state often demands the return of the entire investment for minor irregularities. We handle the process to prevent such injustice.',
        chatbotServices: 'Chatbot Services',
        chatbotServicesDetails: 'Our intelligent chatbot is available to answer all your legal questions in any language. This service is paid for a symbolic fee.',
        onlineAppointment: 'Online Appointment with a Lawyer',
        onlineAppointmentDetails: 'Schedule your appointment with a specialized lawyer within the next 24 hours and receive the support you need. Our services are paid.'
    },
    'Greek': {
        titleOfList: 'Οι Υπηρεσίες μας',
        legalAdvice: 'Νομικές Συμβουλές 24/7',
        legalAdviceDetails: 'Οι δικηγόροι μας είναι διαθέσιμοι όλο το 24ωρο για να απαντήσουν σε όλες τις νομικές σας ερωτήσεις, από επείγοντα θέματα έως καθημερινές νομικές ανησυχίες.',
        familyLaw: 'Οικογενειακό Δίκαιο',
        familyLawDetails: 'Παρέχουμε υποστήριξη σε θέματα διαζυγίου, επιμέλειας παιδιών, διατροφής και άλλων υποθέσεων οικογενειακού δικαίου.',
        criminalLaw: 'Ποινικό Δίκαιο',
        criminalLawDetails: 'Εξειδικευμένη άμυνα και συμβουλές σε ποινικές υποθέσεις, διασφαλίζοντας ότι τα δικαιώματά σας προστατεύονται.',
        laborLaw: 'Εργατικό Δίκαιο',
        laborLawDetails: 'Βοήθεια σε ζητήματα όπως εργατικές διαφορές, απολύσεις, μισθοί και άλλα εργασιακά δικαιώματα.',
        civilLaw: 'Αστικό Δίκαιο',
        civilLawDetails: 'Καλύπτουμε ένα ευρύ φάσμα αστικών υποθέσεων, διαφόρων βαθμών δυσκολίας.',
        personalDataLaw: 'Νόμος για τα Προσωπικά Δεδομένα',
        personalDataLawDetails: 'Η παραβίαση της ιδιωτικής ζωής είναι μια πληγή της εποχής μας. Υπάρχει προστασία, αρκεί να γνωρίζετε πού να την βρείτε.',
        debtReliefLaw: 'Νόμος για την Ελάφρυνση Χρεών για Φυσικά και Νομικά Πρόσωπα',
        debtReliefLawDetails: 'Η υπερχρέωση είναι το νούμερο ένα ζήτημα που προκαλεί αβεβαιότητα. Με το σωστό σχέδιο και κατεύθυνση, υπάρχει πάντα μια άλλη επιλογή.',
        taxLaw: 'Φορολογικό Δίκαιο',
        taxLawDetails: 'Η σχέση μεταξύ του κράτους και των πολιτών στην Ελλάδα είναι γνωστή για τη φιλοξενία της. Μόνο με ειδικότητα και σωστή καθοδήγηση μπορείτε να αποφύγετε απαίσιους πρόστιμους και ποινές από τις φορολογικές αρχές.',
        realEstateLaw: 'Νόμος για τα Ακίνητα',
        realEstateLawDetails: 'Μισθώσεις, μεταφορές, ζητήματα πολεοδομίας, κατανομή περιουσίας μεταξύ κληρονόμων—αυτά είναι μόνο μερικά από τα καθημερινά ζητήματα που αντιμετωπίζουν οι ιδιοκτήτες ακινήτων στην Ελλάδα.',
        enforcementLaw: 'Νόμος για την Επιβολή και Πρόληψη Κατασχέσεων',
        enforcementLawDetails: 'Έγκαιρα και εντός αυστηρών χρονικών ορίων, η διακοπή διαδικασιών κατάσχεσης είναι το καλύτερο διαπραγματευτικό εργαλείο για μελλοντικές συζητήσεις με οποιονδήποτε από τους πιστωτές σας.',
        compulsoryExpropriationLaw: 'Νόμος για Υποχρεωτική Απαλλοτρίωση',
        compulsoryExpropriationLawDetails: 'Χάσατε την περιουσία σας στο κράτος και δεν έχετε αποζημιωθεί; Διαχειριζόμαστε όλη τη διαδικασία εξασφάλισης της πληρωμής σας ή ακόμη και της απελευθέρωσης της περιουσίας σας.',
        trafficAccidents: 'Τροχαία Ατυχήματα',
        trafficAccidentsDetails: 'Η συνεργασία με ασφαλιστικές εταιρείες μπορεί να είναι δύσκολη. Λάβετε την αποζημίωση που πραγματικά αξίζετε, χωρίς περικοπές ή ψιλά γράμματα στις ασφαλιστικές πολιτικές.',
        medicalMalpractice: 'Ιατρική Αμέλεια και Αμέλεια του Συστήματος Υγείας',
        medicalMalpracticeDetails: '...',
        refundsFromInvestmentLaws: 'Επιστροφές από Επενδυτικούς Νόμους και Ευρωπαϊκά Προγράμματα',
        refundsFromInvestmentLawsDetails: 'Το κράτος συχνά απαιτεί την επιστροφή ολόκληρης της επένδυσης για μικρές παρατυπίες. Διαχειριζόμαστε όλη τη διαδικασία, τόσο διοικητικά όσο και μέσω των δικαστηρίων.',
        chatbotServices: 'Υπηρεσίες Chatbot',
        chatbotServicesDetails: 'Ο έξυπνος chatbot μας είναι διαθέσιμος για να απαντήσει σε όλες τις νομικές σας ερωτήσεις σε οποιαδήποτε γλώσσα.',
        onlineAppointment: 'Online Ραντεβού με Δικηγόρο',
        onlineAppointmentDetails: 'Προγραμματίστε το ραντεβού σας με έναν εξειδικευμένο δικηγόρο εντός των επόμενων 24 ωρών και λάβετε την υποστήριξη που χρειάζεστε. Οι υπηρεσίες μας είναι πληρωμένες.'
    },
}
