import { useState } from 'react';

const ToggleInstruction = ({text1, text2}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ marginTop: '20px'}}>
      <div className='instruction_cntr_item' onClick={toggleContent} style={{ cursor: 'pointer' }}>
        <div className='instruction_cntr_item_text'>
          {text1}
        </div>
        
        <span className='svg_minus'>
          {isOpen ? (
            // SVG иконка минус
            <svg width="40" height="2" viewBox="0 0 40 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="1" x2="40" y2="1" stroke="#F5F5F5" strokeWidth="2" />
            </svg>
          ) : (
            // SVG иконка плюс
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.8966 40V0H21.1034V40H18.8966ZM0 21.1034V18.8966H40V21.1034H0Z" fill="#F5F5F5" />
            </svg>
          )}
        </span>
      </div>
      {isOpen && (
        <div className='instruction_cntr_item_full'>
          {text2}
        </div>
      )}
    </div>
  );
};

export default ToggleInstruction;
