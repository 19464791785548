import React from 'react';
import './aboutUs.css';
import { translations } from '../home/translation';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';

const Instructions = () => {
    const language = useSelector(selectLanguage);
    const t = translations[language];
    return (
        <div className='legal-services' style={{height: '100%'}}>
            <h2>{t.instructionsTitle}</h2>
            <p style={{width: '80%'}}>{t.instructionsText1}</p>
            <p>{t.instructionsText2}</p>
        </div>
    );
};

export default Instructions;
