import React, { useState } from 'react';
import main_logo from '../../static/img/logo_main.png'
import './mainBlock.css'
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectLanguage, selectShowModal, selectStatus, setShowModal } from '../home/homeSlice';
import { getPayLink } from '../profile/apiProfile';
import ModalData from '../mainPage/modalData';
import { sendRegister } from '../login/apiLogin';
import { translations } from '../home/translation';
import BtnChat from '../buttons/btnChat';

const MainBlock = ({ handleMenuClick, setActiveMenu }) => {

    const email = useSelector(selectEmail)
    const status = useSelector(selectStatus)
    const language = useSelector(selectLanguage)
    const showModal = useSelector(selectShowModal)
    const dispatch = useDispatch()

    const handleBuySubscription = async () => {
        // Logic for buying subscription, this is just a placeholder
        const paylink = await getPayLink(email)
        console.log('paylink', paylink)
        window.open(paylink.paymentLink, '_blank', 'noopener,noreferrer');
    };

    const handleOnlineClick = () => {
        console.log('handleOnlineClick', email, status)
        if (email === '') setActiveMenu('login')
        else if (status !== 'subscribe') handleBuySubscription()
        else handleMenuClick('ai')
    }

    const handleClickBooking = () => {
        dispatch(setShowModal(true))
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRegisterAndBuy = async (email, phone, name, surname) => {
        const result = await sendRegister(email, phone, name, surname);
        if (result) {
            handleBuySubscription();
        }
    };

    return (
        <div className="instruction_page_container">            
            <div className="left-block-instruction">
                <label className='instruction_main_text' style={{ color: '#FFF', width: '670px', marginTop: '126px' }}>{translations[language].forLawyers}</label>
                {/* <br /> */}
                <label className='lawyer_main_subtext'>{translations[language].forLawyersSubText}</label>

                <div style={{ display: 'flex', gap: '20px', marginTop: '50px' }}>
                    {/* <div className="btn_online_txt btn_online" onClick={handleOnlineClick}>
                        {translations[language].onlineLawyerChat}
                    </div> */}
                    <BtnChat/>
                    <div className="btn_booling_txt btn_booling" onClick={handleClickBooking}>
                        {translations[language].booking24}
                    </div>
                </div>
            </div>


            <ModalData
                show={showModal}
                handleClose={handleModalClose}
                handleSubmit={handleRegisterAndBuy}
            />



        </div>

    );
}

export default MainBlock;


