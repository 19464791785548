import React, { useState } from 'react';
import './services.css';
import './instruction.css';
import { svg_advantage } from '../../static/svg'
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, setShowModal } from './homeSlice';
import { translations } from './translation';
import { useNavigate } from 'react-router-dom';



const Instruction = ({ setActiveMenu }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const language = useSelector(selectLanguage)
    const handleClickChat = () => {
        navigate('/chat/start')
    }
    
    const handleClickBooking = () => {
        dispatch(setShowModal(true));
    };

    return (
        // <div style={{marginTop: '140px'}} className='cntr_instruction'>
        //     <div className="services_title_cntr">
        //         <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label>
        //         <div className='title1_line'></div>
        //         <label style={{marginTop: '20px'}} className='title1_wellcome2'>
        //             ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ
        //         </label>
        //     </div>

        //     <div style={{marginTop: '60px'}} className='row_instruction'>
        //         <div className='cntr_instruction_item'>
        //             <div className='title_instruction_item'>Online ΔικηγΟρος Chat</div>
        //             <div className='text_intruction_item'>
        //                 Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.
        //             </div>
        //             <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '20px' }}>
        //                 Online Δικηγόρος Chat
        //             </div>
        //         </div>
        //         <div className='cntr_instruction_item'>
        //             <div className='title_instruction_item'>ΚρΑτηση 24</div>
        //             <div className='text_intruction_item'>
        //             Αν έχετε ένα δύσκολο ζήτημα, μπορείτε να χρησιμοποιήσετε το κουμπί "Κράτηση 24". Οι δικηγόροι μας θα το εξετάσουν και θα επικοινωνήσουν μαζί σας εντός 24 ωρών.
        //             </div>
        //             <div className='instruction_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '20px' }}>
        //                 Κράτηση 24/7
        //             </div>
        //         </div>
        //     </div>
        //     <div className='services_title_cntr'>
        //     {/* style={{ width: '1300px', marginTop: '40px' }} */}
        //         <div className='instruction_widget_button' > 
        //             Μάθετε περισσότερα
        //         </div>

        //     </div>
        // </div>

        <div style={{ marginTop: '140px' }} className='cntr_instruction'>
            <div className="services_title_cntr">
                <label className='title1_wellcome'>
                    {translations[language].servicesTitle}
                </label>
                <div className='title1_line'></div>
                <label style={{ marginTop: '20px' }} className='title1_wellcome2'>
                    {translations[language].ourServices}
                </label>
            </div>

            <div style={{ marginTop: '60px' }} className='row_instruction'>
                <div className='cntr_instruction_item'>
                    <div className='title_instruction_item'>
                        {translations[language].onlineLawyerChat}
                    </div>
                    <div className='text_intruction_item'>
                        {translations[language].onlineLawyerDescription}
                    </div>
                    <div className='instruction_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '20px' }}
                        onClick={handleClickChat}
                    >
                        {translations[language].onlineLawyerButton}
                    </div>
                </div>
                <div className='cntr_instruction_item'>
                    <div className='title_instruction_item'>
                        {translations[language].booking24}
                    </div>
                    <div className='text_intruction_item'>
                        {translations[language].booking24Description}
                    </div>
                    <div className='instruction_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '20px' }}
                        onClick={handleClickBooking}
                    >
                        {translations[language].booking24Button}
                    </div>
                </div>
            </div>
            <div className='services_title_cntr'>
                <div className='instruction_widget_button'>
                    {translations[language].learnMore}
                </div>
            </div>
        </div>

    );
};

export default Instruction;
