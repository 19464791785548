// home.js
import React, { useState } from 'react';
import './whoWe.css';
import logo_menu from '../../static/img/logo_menu.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectLanguage, setEmail } from '../home/homeSlice';
import { svg_out, svg_burger } from '../../static/svg';
import LanguageSelector from '../home/languageSelector';
import { translations } from '../home/translation';
import Instruction from '../home/instruction';
import Footer from '../home/footer';
import MainBlock from './mainBlock';
import Questions from './questions';
import NavMenu from '../navMenu/navMenu';

function WhoWe() {
    const dispatch = useDispatch()
    const email = useSelector(selectEmail);
    const [activeMenu, setActiveMenu] = useState('main');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const language = useSelector(selectLanguage)

    const handleMenuClick = (menu) => {
        setIsMenuOpen(false);
        if (menu === 'main' && email === '') {
            setActiveMenu('login');
        }
        else {
            setActiveMenu(menu);
        }
    };

    return (

        <div className="home-container">
            <div className='cntr_line'>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>
            <div className='cntr_line2'>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>

            <NavMenu/>

            {activeMenu === 'main' && <MainBlock handleMenuClick={handleMenuClick} setActiveMenu={setActiveMenu} />}
            
            {/* <Services setActiveMenu={setActiveMenu}/> */}
            {/* <Advantage/> */}
            {/* <Booking/> */}
            {/* <Instruction/> */}
            <Questions/>
            {/* <Comments/> */}
            <Footer handleMenuClick={handleMenuClick}/>
        </div>

    );
}

export default WhoWe;
