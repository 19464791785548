// App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/home';
import ServicesPage from './components/servicesPage/servicesPage';
import InstructionPage from './components/instructionsPage/instructionPage';
import OnlineChat from './components/onlineChat/onlineChat';
import OnlineConsultant from './components/consultant/consultant';
import LawyersPage from './components/Lawyers/lawyersPage';
import WhoWe from './components/whoWe/whoWe';
import TermsUse from './components/termsUse/termsUse';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/instructions" element={<InstructionPage />} />
          <Route path="/lawyers" element={<LawyersPage />} />
          <Route path="/chat" element={<OnlineChat />} />
          <Route path="/whowe" element={<WhoWe />} />
          <Route path="/termsuse" element={<TermsUse />} />
          <Route path="/chat/start" element={<OnlineConsultant />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
