// languageSelector.js
import React, { useState } from 'react';
import { svg_globus } from '../../static/svg';
import { setLanguage } from './homeSlice';
import { useDispatch } from 'react-redux';
import './home.css'

const LanguageSelector = () => {
  const dispatch = useDispatch()
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('Greek');

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const selectLanguage = (lang) => {
    setSelectedLanguage(lang);
    dispatch(setLanguage(lang));  // Устанавливает язык в компоненте Home
    setMenuVisible(false);
  };

  const getLanguageCode = () => {
    switch (selectedLanguage) {
      case 'Greek':
        return 'GR';
      case 'Russian':
        return 'RU';
      case 'English':
        return 'EN';
      default:
        return 'GR';
    }
  };

  return (
    <div className='language-selector'>
      <div className='svg_globus_home' onClick={toggleMenu}>
        {/* <div>{svg_globus}</div> */}
        <div className='language-code'>{getLanguageCode()}</div>
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.61138 5.90771L0.160979 1.45728C-0.0536598 1.24264 -0.0536598 0.894658 0.160979 0.680042L0.680047 0.160974C0.89432 -0.0532986 1.24159 -0.053711 1.45637 0.160058L5.00001 3.6871L8.54363 0.160058C8.75841 -0.053711 9.10568 -0.0532986 9.31995 0.160974L9.83902 0.680042C10.0537 0.894681 10.0537 1.24267 9.83902 1.45728L5.38864 5.90771C5.174 6.12232 4.82602 6.12232 4.61138 5.90771Z" fill="#F5F5F5" />
        </svg>

      </div>
      {menuVisible && (
        <div className='language-menu'>
          <ul>
            <li onClick={() => selectLanguage('Greek')}>Greek</li>
            <li onClick={() => selectLanguage('Russian')}>Russian</li>
            <li onClick={() => selectLanguage('English')}>English</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
