// Login.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, setDatetime, setEmail, setStatus } from '../home/homeSlice';
import './login.css';
import { url_back } from '../../static/vars';
import { formatDateTime } from '../funcs';
import { translations } from '../home/translation';
import { handleLogin } from './apiLogin';

function Login({ setActiveMenu }) {
  const [email, setEmailInput] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage)

  const clickLogin = async () => {
    // Отправка запроса на бэкэнд для авторизации
    const response = await fetch(url_back + '/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });

    if (response.ok) {
      const data = await handleLogin( email, password)
      console.log('login', data)
      dispatch(setStatus(data.status))
      dispatch(setEmail(data.email));
      dispatch(setDatetime(formatDateTime(data.datetime)))
      setActiveMenu('main');
    } else {
      alert('Ошибка авторизации');
    }
  };

  return (
    <div className="auth-container">
      <h2>{translations[language].authorization}</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmailInput(e.target.value)}
      />
      <input
        type="password"
        placeholder={translations[language].password}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={clickLogin}>{translations[language].login}</button>
      <p onClick={() => setActiveMenu('reg')}>{translations[language].registration}</p>
    </div>
  );
}

export default Login;
