import React, { useState } from 'react';
import './services.css';
import './booking.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { translations } from './translation';
import {sendLawyerQuestion} from '../lawyer/apiLawyer'



const Booking = ({ setActiveMenu }) => {
    const language = useSelector(selectLanguage)
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [question, setQuestion] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleClickContinue = () => {
        sendLawyerQuestion(name, email, phone, question)
    }


    return (
        <div className='main_cntr_booking'>
            <div className='cntr_booking' style={{ marginTop: '140px' }}>
                <div className='subcntr_booking'>
                    <div className='title_booking'>{translations[language].bookAppointment}</div>

                    <div style={{ marginTop: '40px' }} className='row_input_booking'>
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                            }}
                            className='login_widget_input'
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].password}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className='row_input_booking'>
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                            }}
                            className='login_widget_input'
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].password}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className='cntr_booking_input_mob'>
                        <input
                            style={{ marginTop: '60px' }}
                            className='booking_widget_input'
                            type="email"
                            placeholder={translations[language].modal_emailPlaceholder}
                            value={email}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <input
                            className='booking_widget_input'
                            type="phone"
                            placeholder={translations[language].modal_phonePlaceholder}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <input
                            className='booking_widget_input'
                            // type="password"
                            placeholder={translations[language].modal_namePlaceholder}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            style={{
                                WebkitBoxShadow: '0 0 0 1000px transparent inset',
                                boxShadow: '0 0 0 1000px transparent inset',
                                WebkitTextFillColor: 'inherit',
                                backgroundColor: 'transparent',
                            }}
                            className='login_widget_input'
                            // type="email"
                            placeholder={translations[language].modal_surnamePlaceholder}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                        {/* <input
                        className='login_widget_input'
                        type="password"
                        placeholder={translations[language].password}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    /> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                            style={{ width: '740px', height: '70px' }}
                            className='login_widget_input'
                            type="password"
                            placeholder={translations[language].modal_questionPlaceholder}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='btn_booking'>
                        Συνέχεια
                    </div>
                </div>

                <div className='subtitle_boking'>
                    Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων
                </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='btn_booking' onClick={handleClickContinue}>
                            {translations[language].continue}
                        </div>
                    </div>

                    {/* <div className='subtitle_boking'>
                    {translations[language].consentForDataProcessing}
                </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='booking_bottom_cntr'>
                            <div className='modal_checkbox'>
                                <input
                                    className='modal_checkbox'
                                    type='checkbox'
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className='modal_bottom_text' style={{ width: '270px', margin: 0 }}>
                                {translations[language].modal_consentText}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Booking;
