// translations.js
export const translations = {
  Greek: {
    home: 'ΑΡΧΙΚΗ',
    aboutUs: 'ΠΟΙΟΙ ΕΙΜΑΣΤΕ',
    instructions: 'ΟΔΗΓΙΕΣ',
    forLawyers: 'ΓΙΑ ΔΙΚΗΓΟΡΟΥΣ',
    forLawyersSubText: `Σε περίπτωση που θέλετε να μπείτε στην ομάδα μας , συμπληρώστε τα στοιχεία στην παρακάτω φόρμα. Εάν πληροίτε τις προϋποθέσεις θα επικοινωνήσουμε μαζί σας για να ενταχθείτε στην ομάδα μας.`,
    profile: 'Προφίλ',
    authorization: 'Εξουσιοδότηση',
    registration: 'Εγγραφή',
    mainText: 'Η ΑΝΑΖΗΤΗΣΗ ΤΟΥ ΔΙΚΑΙΟΥ',
    mainSubtext: 'Η εμπειρία μας, η γαλήνη σας. 24/7 κοντά σε εσάς και τα προβλήματα σας.',
    mainServicesSubtext: `Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας 
    σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε.`,
    mainServicesText: 'Οι ΥπηρεσΙες μας',
    mainInstructionText: `ΠΩΣ ΝΑ ΠΑΡΕΤΕ ΜΙΑ ΔΙΑΒΟΥΛΕΥΣΗ`,
    mainInstructionSubText: `Οδηγίες για τη λήψη νομικών συμβουλών και βοήθειας στο Διαδίκτυο.`,
    onlineLawyerChat: 'Online Δικηγόρος Chat',
    booking24: 'Κράτηση 24',
    password: 'Κωδικός',
    login: 'Είσοδος',
    welcome: 'Καλώς ήρθατε στην DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα . 24/7 κοντά σε εσάς και τα προβλήματα σας .',
    introText: 'Στην DIKIGOROS ONLINE, φέρνουμε την επανάσταση στην παροχή νομικών υπηρεσιών, προσφέροντας εύκολη και άμεση πρόσβαση σε εξειδικευμένους δικηγόρους μέσω διαδικτύου. Είμαστε αφοσιωμένοι στο να παρέχουμε στους πολίτες της χώρας μας αξιόπιστη νομική υποστήριξη, οποιαδήποτε στιγμή την χρειαστούν. Προσφέρουμε την άμεση και τεκμηριωμένη απάντηση που ζητάτε, ακόμη και πριν αναθεσετε την ΥΠΟΘΕΣΗ σε ένα απο τους εξειδικευμένους δικηγόρους μας, μέσω της καινοτόμου διαδικτυακής υπηρεσίας μας Chatbot.',
    ourServices: 'Οι Υπηρεσίες μας:',
    legalAdvice: 'Νομικές Συμβουλές 24/7: Οι δικηγόροι μας είναι διαθέσιμοι όλο το 24ωρο για να απαντήσουν σε όλα σας τα νομικά ερωτήματα, από επείγοντα ζητήματα μέχρι καθημερινές νομικές ανησυχίες.',
    familyLaw: 'Οικογενειακό Δίκαιο: Σας παρέχουμε υποστήριξη σε θέματα διαζυγίων, επιμέλειας παιδιών, διατροφής και άλλων οικογενειακών υποθέσεων.',
    criminalLaw: 'Ποινικό Δίκαιο: Εξειδικευμένη υπεράσπιση και συμβουλευτική σε ποινικές υποθέσεις, διασφαλίζοντας τα δικαιώματά σας.',
    laborLaw: 'Εργατικό Δίκαιο: Βοηθάμε σε ζητήματα εργασιακών διαφορών, απολύσεων, αμοιβών και άλλων εργασιακών δικαιωμάτων.',
    civilLaw: 'Αστικό Δίκαιο: Καλύπτουμε ένα ευρύ φάσμα αστικών υποθέσεων, μικρής , μέσης και διαβαθμισμένης δυσκολίας .',

    personalDataLaw: 'ΔΙΚΑΙΟ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ',
    personalDataLawText: 'Η προσβολή της ιδιωτικότητας του ατόμου στις μέρες μας είναι η μάστιγα της εποχής. Υπάρχει προστασία αρκεί να ξέρεις να την βρεις.',
    personalRehabilitationLaw: 'ΔΙΚΑΙΟ ΕΞΥΓΙΑΝΣΗΣ ΦΥΣΙΚΩΝ ΠΡΟΣΩΠΩΝ ΚΑΙ ΕΠΙΧΕΙΡΗΣΕΩΝ :',
    personalRehabilitationLawText: 'Η υπερχρέωση αποτελεί το νούμερο ένα ζήτημα και προκαλεί αβεβαιότητα. Με σωστό πλάνο και κατεύθυνση όμως πάντα υπάρχει και η άλλη επιλογή.',
    taxLaw: 'ΦΟΡΟΛΟΓΙΚΟ ΔΙΚΑΙΟ :',
    taxLawText: 'Η σχέση κράτους πολίτη στην Ελλάδα είναι όπως όλοι ομολογούν εχθρική. Μόνο με εξειδίκευση και ορθή κατεύθυνση αποφεύγεις δυσβάσταχτα πρόστιμα και προσαυξήσεις από τις φορολογικές αρχές.',
    realEstateLaw: 'ΔΙΚΑΙΟ ΑΚΙΝΗΤΩΝ :',
    realEstateLawText: 'Μισθώσεις, μεταβιβάσεις, πολεοδομικά θέματα, διανομή περιουσίας μεταξύ κληρονόμων μερικά από τα καθημερινά ζητήματα που απασχολούν τον ιδιοκτήτη ακινήτου στην Ελλάδα. Τρόποι και πλάνο ορθής και συνετής διαχείρισης περιουσίας η μόνη επιλογή.',
    enforcementLaw: 'ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΗΣ ΕΚΤΕΛΕΣΗΣ ΚΑΙ ΑΠΟΤΡΟΠΗΣ ΠΛΕΙΣΤΗΡΙΑΣΜΩΝ.',
    enforcementLawText: 'Η έγκαιρη και εντός των αυστηρών χρονικών ορίων ανακοπή των διαδικασιών πλειστηριασμού αποτελεί το καλύτερο διαπραγματευτικό όπλο για μελλοντικές συζητήσεις σας με κάθε πιστωτή σας.',


    expropriationLaw: 'ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΩΝ ΑΠΑΛΛΟΤΡΙΩΣΕΩΝ.',
    expropriationLawText: 'Έχετε χάσει την περιουσία σας από το κράτος και δεν έχετε αποζημιωθεί; Αναλαμβάνουμε όλη τη διαδικασία πληρωμής σας ή ακόμη και της αποδέσμευσης της περιουσίας σας για να την αξιοποιήσετε όπως εσείς επιθυμείτε.',
    trafficAccidentsLaw: 'ΤΡΟΧΑΙΑ ΑΤΥΧΗΜΑΤΑ :',
    trafficAccidentsLawText: 'Μια δύσκολη περίπτωση η έμπλοκη σας με τις ασφαλιστικές εταιρείες. Εισπράξτε την αποζημίωση που πραγματικά δικαιούστε, χωρίς περικοπές ή τα ψιλά γράμματα των ασφαλιστηρίων συμβολαίων.',
    medicalMalpractice: 'ΙΑΤΡΙΚΑ ΛΑΘΗ',
    medicalMalpracticeText: 'και Παραλείψεις του συστήματος υγείας.',
    subsidyReturns: 'ΕΠΙΣΤΡΟΦΕΣ ΕΠΙΔΟΤΗΣΕΩΝ ΑΠΟ ΕΠΕΝΔΥΤΙΚΟ ΝΟΜΟ ΚΑΙ ΕΣΠΑ.',
    subsidyReturnsText: 'Συχνά το κράτος για μικρές παρατυπίες ζητά επιστροφή του συνόλου της επένδυσης. Αντιμετωπίζουμε την όλη κατάσταση τόσο ενδοδιοικητικά όσο και με προσφυγή στα αρμόδια δικαστήρια προς αποτροπή αυτής της αδικίας εις βάρος σας.',
    chatbotServices: 'Υπηρεσίες μέσω Chatbot:',
    chatbotServicesText: 'Το έξυπνο chatbot μας είναι διαθέσιμο να απαντήσει σε όλες τις νομικές σας ερωτήσεις σε οποιαδήποτε γλώσσα. Απλά πληκτρολογήστε το μήνυμά σας στη γλώσσα που προτιμάτε. Η υπηρεσία αυτή είναι επι πληρωμή αντί συμβολικού τιμήματος για την κάλυψη του κόστους συντήρησης μιας τεράστιας βάσης νομικών δεδομένων.',
    onlineLawyerAppointment: 'Online Ραντεβού με Δικηγόρο:',
    onlineLawyerAppointmentText: 'Κλείστε το ραντεβού σας με εξειδικευμένο δικηγόρο μέσα στις επόμενες 24 ώρες και λάβετε την υποστήριξη που χρειάζεστε. Οι υπηρεσίες μας είναι επί πληρωμή.',

    howWeCanHelp: 'Πώς μπορούμε να σας βοηθήσουμε:',
    easyAccess: 'Εύκολη Πρόσβαση:',
    easyAccessText: 'Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.',
    immediateResponse: 'Άμεση Ανταπόκριση:',
    immediateResponseText: 'Είμαστε εδώ για να σας προσφέρουμε άμεση βοήθεια όποτε την χρειάζεστε, χωρίς καθυστερήσεις.',
    expertiseExperience: 'Εξειδίκευση και Εμπειρία:',
    expertiseExperienceText: 'Η ομάδα μας αποτελείται από έμπειρους δικηγόρους με εξειδίκευση σε διάφορους τομείς του δικαίου, εξασφαλίζοντας την καλύτερη δυνατή υποστήριξη.',
    affordableServices: 'Προσιτές Υπηρεσίες:',
    affordableServicesText: 'Προσφέρουμε οικονομικά προσιτές λύσεις για να εξασφαλίσουμε ότι η νομική υποστήριξη είναι προσβάσιμη σε όλους τους πολίτες 24/7.',
    contactUs: 'Επικοινωνήστε μαζί μας:',
    contactUsText: 'Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε. Είμαστε εδώ για εσάς, κάθε στιγμή της ημέρας, κάθε ημέρα της εβδομάδας.',
    dikigorosOnline: 'DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα',

    instructionsTitle: 'Οδηγίες',
    instructionsText1: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό, στην αρχική σελίδα κάντε κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.',
    instructionsText2: 'Αν έχετε ένα δύσκολο ζήτημα, μπορείτε να χρησιμοποιήσετε το κουμπί "Κράτηση 24". Οι δικηγόροι μας θα το εξετάσουν και θα επικοινωνήσουν μαζί σας εντός 24 ωρών.',
    onlineChatText1: 'Online ',
    onlineChatText1_1: 'ΔικηγOρος Chat',
    onlineChatText2: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.',


    menuButtonBooking: 'Κράτηση 24/7',
    menuItemServices: 'ΥΠΗΡΕΣΙΕΣ ',

    whoWeAre: 'ΠΟΙΟΙ ΕΙΜΑΣΤΕ',
    welcomeMessage: 'Καλώς ήρθατε στην DIKIGOROS ONLINE',
    introductionText: 'Στην DIKIGOROS ONLINE, φέρνουμε την επανάσταση στην παροχή νομικών υπηρεσιών, προσφέροντας εύκολη και άμεση πρόσβαση σε εξειδικευμένους δικηγόρους μέσω διαδικτύου. Είμαστε αφοσιωμένοι στο να παρέχουμε στους πολίτες της χώρας μας αξιόπιστη νομική υποστήριξη, οποιαδήποτε στιγμή την χρειαστούν.',
    serviceDescription: 'Προσφέρουμε την άμεση και τεκμηριωμένη απάντηση που ζητάτε, ακόμη και πριν αναθέσετε την υπόθεση σε έναν από τους εξειδικευμένους δικηγόρους μας, μέσω της καινοτόμου διαδικτυακής υπηρεσίας μας Chatbot.',
    callToAction: 'Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε. Είμαστε εδώ για εσάς, κάθε στιγμή της ημέρας, κάθε ημέρα της εβδομάδας.',
    closingText: 'DIKIGOROS ONLINE - Νομικές Υπηρεσίες Παντού και Πάντα.',

    legalAdviceTitle: 'ΝομικΕς ΣυμβουλΕς 24/7',
    legalAdviceText: 'Οι δικηγόροι μας είναι διαθέσιμοι όλο το 24ωρο για να απαντήσουν σε όλα σας τα νομικά ερωτήματα, από επείγοντα ζητήματα μέχρι καθημερινές νομικές ανησυχίες.',

    familyLawTitle: 'ΟικογενειακΟ ΔΙκαιο',
    familyLawText: 'Σας παρέχουμε υποστήριξη σε θέματα διαζυγίων, επιμέλειας παιδιών, διατροφής και άλλων οικογενειακών υποθέσεων.',

    criminalLawTitle: 'ΠοινικΟ ΔΙκαιο',
    criminalLawText: 'Εξειδικευμένη υπεράσπιση και συμβουλευτική σε ποινικές υποθέσεις, διασφαλίζοντας τα δικαιώματά σας.',

    laborLawTitle: 'ΕργατικΟ ΔΙκαιο',
    laborLawText: 'Βοηθάμε σε ζητήματα εργασιακών διαφορών, απολύσεων, αμοιβών και άλλων εργασιακών δικαιωμάτων.',

    civilLawTitle: 'ΑστικΟ ΔΙκαιο',
    civilLawText: 'Καλύπτουμε ένα ευρύ φάσμα αστικών υποθέσεων, μικρής , μέσης και διαβαθμισμένης δυσκολίας.',

    servicesTitle: 'ΥΠΗΡΕΣΙΕΣ',
    ourServicesTitle: 'ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ',


    dataProcessingConsent: 'Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων',
    otherServices: 'Υπόλοιπες υπηρεσίες',

    advantage: 'ΠΛΕΟΝΕΚΤΗΜΑ',
    howCanHelp: 'ΠΩΣ ΜΠΟΡΟΥΜΕ ΝΑ ΣΑΣ ΒΟΗΘΗΣΟΥΜΕ',
    easyAccessDescription: 'Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.',

    immediateResponseDescription: 'Είμαστε εδώ για να σας προσφέρουμε άμεση βοήθεια όποτε την χρειάζεστε, χωρίς καθυστερήσεις.',

    expertiseExperienceDescription: 'Η ομάδα μας αποτελείται από έμπειρους δικηγόρους με εξειδίκευση σε διάφορους τομείς του δικαίου, εξασφαλίζοντας την καλύτερη δυνατή υποστήριξη.',

    affordableServicesDescription: 'Προσφέρουμε οικονομικά προσιτές λύσεις για να εξασφαλίσουμε ότι η νομική υποστήριξη είναι προσβάσιμη σε όλους τους πολίτες 24/7.',


    continue: 'Συνέχεια',
    consentForDataProcessing: 'Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων',

    bookAppointment: 'ΚΛΕΙΣΤΕ ΡΑΝΤΕΒΟΥ',
    onlineLawyerDescription: 'Στην ιστοσελίδα μας μπορείτε να χρησιμοποιήσετε την online συμβουλή με τον εικονικό βοηθό. Για αυτό κλικ στο κουμπί "Online Δικηγόρος Chat". Θα χρειαστεί να πληρώσετε μια συνδρομή, η οποία θα ισχύει για 24 ώρες.',
    onlineLawyerButton: 'Online Δικηγόρος Chat',
    booking24Description: 'Αν έχετε ένα δύσκολο ζήτημα, μπορείτε να χρησιμοποιήσετε το κουμπί "Κράτηση 24". Οι δικηγόροι μας θα το εξετάσουν και θα επικοινωνήσουν μαζί σας εντός 24 ωρών.',
    booking24Button: 'Κράτηση 24/7',
    learnMore: 'Μάθετε περισσότερα',

    services: 'ΥΠΗΡΕΣΙΕΣ',
    whatTheySay: 'Τι εχουν πει',
    comments: [
      {
        name: 'Georgia Karamolegkou',
        text: 'Άριστος επαγγελματίας με κατάρτιση, εμπειρία και ήθος. Αδιαμφισβήτητο χάρισμα η ανθρωπιά και η ειλικρίνεια. Αξιόπιστος, με συνέπεια και εξαιρετικά αφοσιωμένος στην υπόθεση που είχε αναλάβει εκ μέρους μας. Υπήρξε μια άψογη συνεργασία. Εν κατακλείδι, αυτό που θέλω να εκφράσω είναι ένα ΕΥΧΑΡΣΤΩ και συνεχίστε με τον ίδιο σεβασμό να ασκείται το λειτούργημα της δικηγορίας.'
      },
      {
        name: 'ΝΙΚΟΛΑΟΣ ΜΕΞΑΣ',
        text: 'ΕΞΑΙΡΕΤΟΣ ΑΝΘΡΩΠΟΣ ΚΑΙ ΑΡΙΣΤΟΣ ΕΠΙΣΤΗΜΟΝΑΣ. Για μένα είναι πρώτα από όλα άνθρωπος με ήθος και αξιοπρέπεια. Είναι πολύ ευγενικός, χαμογελαστός και αισθάνεσαι οικειότητα από το πρώτο λεπτό. Ως δικηγόρος είναι ένας άριστος επιστήμονας, με υψηλή νομική κατάρτιση και σεβασμό στον πελάτη του, ακούει προσεκτικά και απαντά σε όλες τις ερωτήσεις. Τον συνιστώ ανεπιφύλακτα.'
      },
      {
        name: 'ΣΩΤΗΡΗΣ ΤΡΙΒΕΛΛΑΣ',
        text: 'Προσωπική μου άποψη ύστερα από πολλά χρόνια συνεργασίας μαζί του απλά ικανός για τα πάντα. Τέρας ηθικής και τιμιότητας!!!'
      }
    ],

    service_legalAdvice: 'ΝομικΕς ΣυμβουλΕς 24/7',
    service_legalAdviceDesc: 'Οι δικηγόροι μας είναι διαθέσιμοι όλο το 24ωρο για να απαντήσουν σε όλα σας τα νομικά ερωτήματα, από επείγοντα ζητήματα μέχρι καθημερινές νομικές ανησυχίες.',

    service_familyLaw: 'ΟικογενειακΟ ΔΙκαιο',
    service_familyLawDesc: 'Σας παρέχουμε υποστήριξη σε θέματα διαζυγίων, επιμέλειας παιδιών, διατροφής και άλλων οικογενειακών υποθέσεων.',

    service_criminalLaw: "Ποινικό Δίκαιο",
    service_criminalLawDesc: "Εξειδικευμένη προστασία και συμβουλευτική σε ποινικές υποθέσεις, εξασφαλίζοντας την προστασία των δικαιωμάτων σας.",


    service_laborLaw: "Εργατικό Δίκαιο",
    service_laborLawDesc: "Βοηθάμε σε ζητήματα εργατικών διαφορών, απολύσεων, μισθών και άλλων εργασιακών δικαιωμάτων.",

    service_civilLaw: "Αστικό Δίκαιο",
    service_civilLawDesc: "Καλύπτουμε ένα ευρύ φάσμα αστικών υποθέσεων, από απλές έως πολύπλοκες.",

    service_personalDataLaw: "Νόμος για τα Προσωπικά Δεδομένα",
    service_personalDataLawDesc: "Η παραβίαση της ιδιωτικότητας ενός ατόμου είναι μια καταστροφή στην εποχή μας. Η προστασία υπάρχει αν ξέρετε πώς να την βρείτε.",

    service_insolvencyLaw: "Νόμος για την Πτώχευση",
    service_insolvencyLawDesc: "Η υπερφόρτωση είναι νούμερο ένα σε προβλήματα, προκαλώντας αβεβαιότητα. Ωστόσο, με το σωστό σχέδιο και καθοδήγηση, υπάρχει πάντα μια άλλη επιλογή.",

    service_taxLaw: "Φορολογικό Δίκαιο",
    service_taxLawDesc: "Η σχέση μεταξύ του κράτους και του πολίτη στην Ελλάδα είναι, όπως όλοι αναγνωρίζουν, εχθρική. Μόνο με τη βοήθεια ειδικών και σωστής καθοδήγησης μπορείτε να αποφύγετε δυσβάστακτους πρόστιμα και αυξήσεις από τις φορολογικές αρχές.",

    service_realEstateLaw: "Δίκαιο Ακινήτων",
    service_realEstateLawDesc: "Περιγραφή υπηρεσιών στον τομέα του δικαίου ακινήτων.",
    service_enforcementLaw: "Εκτελεστικό Δίκαιο",
    service_enforcementLawDesc: "Περιγραφή υπηρεσιών στον τομέα του εκτελεστικού δικαίου.",
    service_compulsoryExpropriation: "Υποχρεωτική Απαλλοτρίωση",
    service_compulsoryExpropriationDesc: "Έχετε χάσει την ιδιοκτησία σας από το κράτος και δεν έχετε αποζημιωθεί; Αναλαμβάνουμε ολόκληρη τη διαδικασία πληρωμής ή ακόμη και την απελευθέρωση της ιδιοκτησίας σας για να τη χρησιμοποιήσετε όπως επιθυμείτε.",
    service_trafficAccidents: "Τροχαία Ατυχήματα",
    service_trafficAccidentsDesc: "Μια δύσκολη υπόθεση που αφορά τις σχέσεις σας με τις ασφαλιστικές εταιρείες. Απαιτήστε την αποζημίωση που πραγματικά δικαιούστε, χωρίς περικοπές ή τα ψιλά γράμματα των ασφαλιστικών πολιτικών.",
    service_medicalErrors: "Ιατρικά Λάθη",
    service_medicalErrorsDesc: "Η έγκαιρη παρέμβαση και η προσφυγή κατά διαδικασιών εκτέλεσης είναι το καλύτερο διαπραγματευτικό εργαλείο σας για μελλοντικές συζητήσεις με τους πιστωτές σας.",
    service_subsidyReturns: "Επιστροφές Επιδοτήσεων από Επενδυτικό Δίκαιο και ΕΣΦ",
    service_subsidyReturnsDesc: "Συχνά, το κράτος απαιτεί την επιστροφή της συνολικής επένδυσης για μικρές ατασθαλίες. Διαχειριζόμαστε αυτήν την κατάσταση τόσο διοικητικά όσο και μέσω προσφυγών στα αρμόδια δικαστήρια για να αποτρέψουμε αυτή την αδικία εις βάρος σας.",
    service_onlineLawyerChat: "Online Chat με Δικηγόρο",
    service_onlineLawyerChatDesc: "Στην ιστοσελίδα μας, μπορείτε να χρησιμοποιήσετε την online συμβουλευτική με τον εικονικό βοηθό. Για αυτό, κάντε κλικ στο κουμπί 'Online Chat με Δικηγόρο'. Θα ισχύει συνδρομητική χρέωση, που θα είναι έγκυρη για 24 ώρες.",
    service_onlineLawyerChatButton: "Online Chat με Δικηγόρο",
    service_booking24: "Κράτηση 24/7",
    service_booking24Desc: "Εάν έχετε ένα δύσκολο ζήτημα, μπορείτε να χρησιμοποιήσετε το κουμπί 'Κράτηση 24/7'. Οι δικηγόροι μας θα το εξετάσουν και θα επικοινωνήσουν μαζί σας εντός 24 ωρών.",
    service_booking24Button: "Κράτηση 24/7",

    instruction_howWeCanHelp: "Πως μπορούμε να σας βοηθήσουμε",
    instruction_howChatBotWorks: "Πως λειτουργεί το chat bot",
    instruction_chatBotCost: "Τι κόστος έχει το chat bot",
    instruction_chatBotLanguagesAndAnswers: "Σε ποιες γλώσσες μιλάει το chat bot και μπορεί να δώσει από απαντήσεις",
    instruction_whatIfNoResponseFromChatBot: "Τι κάνω εάν δεν μου απαντάει το chat bot",
    instruction_howMuchChatBotCanHelp: "Ποσο μπορεί να με βοηθήσει το chat bot",
    instruction_whySendEmailAfterPayment: "Ο λόγος της αποστολής στο e-mail username και password μετά τη πληρωμή",
    instruction_whatIsBooking247: "Τι είναι το booking24/7",
    instruction_booking247Cost: "Τι κόστος έχει η υπηρεσία booking 24/7?",
    instruction_whyTrustUs: "Γιατί να μας εμπιστευτείτε",
    modal_appointmentTitle: "Κλείστε ραντεβού",
    modal_emailPlaceholder: "E-mail",
    modal_phonePlaceholder: "Τηλέφωνο",
    modal_namePlaceholder: "Ονομα",
    modal_surnamePlaceholder: "Επώνυμο",
    modal_questionPlaceholder: "Ερώτηση",
    modal_continueButton: "Συνέχεια",
    modal_consentText: "Δίνω τη συγκατάθεσή μου για την επεξεργασία ",
    modal_consentText2: " προσωπικών δεδομένων",

    lawyers_nameSurnamePlaceholder: "Όνομα επώνυμο",
    lawyers_professionalAddressPlaceholder: "Επαγγελματική έδρα",
    lawyers_registrationNumberPlaceholder: "Αριθμός μητρώου συλλόγου",
    lawyers_associationPlaceholder: "Σύλλογος",
    lawyers_emailPlaceholder: "E-mail",
    lawyers_phonePlaceholder: "Τηλέφωνο",
    lawyers_specializationPlaceholder: "Αντικείμενα εξειδίκευσης σε τομείς δικαίου",
    lawyers_experiencePlaceholder: "Έτη εμπειρίας",
    lawyers_continueButton: "Συνέχεια",
    lawyers_consentText: "Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων",

  },
  Russian: {
    home: 'Главная',
    aboutUs: 'Кто мы',
    instructions: 'Инструкции',
    forLawyers: 'Для юристов',
    forLawyersSubText: "Если вы хотите присоединиться к нашей команде, заполните данные в форме ниже. Если вы соответствуете требованиям, мы свяжемся с вами для присоединения к нашей команде.",
    profile: 'Профиль',
    authorization: 'Авторизация',
    registration: 'Регистрация',
    mainText: 'Поиск справедливости',
    mainSubtext: 'Наш опыт, ваше спокойствие',
    mainServicesSubtext: `Не позволяйте вашим юридическим проблемам вас беспокоить. 
    Свяжитесь с нами сегодня и позвольте нам помочь вам найти нужное решение.`,
    mainServicesText: 'Наши услуги',

    mainInstructionText: `КАК ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ`,
    mainInstructionSubText: `Инструкции по получению юридической консультации и помощи в интернете.`,

    onlineLawyerChat: 'Онлайн чат с адвокатом',
    booking24: 'Бронирование 24',
    password: 'Пароль',
    login: 'Войти',
    welcome: 'Добро пожаловать в DIKIGOROS ONLINE - Юридические услуги Везде и Всегда. 24/7 рядом с вами и ваши проблемы.',
    introText: 'В DIKIGOROS ONLINE мы внедряем революцию в предоставлении юридических услуг, обеспечивая легкий и мгновенный доступ к квалифицированным юристам через интернет. Мы посвящены предоставлению надежной юридической поддержки нашим гражданам в любое время, когда это им необходимо. Мы предлагаем немедленный и обоснованный ответ, который вам нужен, даже до того, как вы поручите ваше дело одному из наших специализированных адвокатов, с помощью нашей инновационной интернет-службы Chatbot.',
    ourServices: 'Наши Услуги:',
    legalAdvice: 'Юридические консультации 24/7: Наши адвокаты доступны круглосуточно для ответов на все ваши юридические вопросы, от срочных вопросов до повседневных юридических проблем.',
    familyLaw: 'Семейное право: Мы предоставляем поддержку по вопросам развода, опеки над детьми, алиментов и других семейных дел.',
    criminalLaw: 'Уголовное право: Специализированная защита и консультации по уголовным делам, обеспечивая защиту ваших прав.',
    laborLaw: 'Трудовое право: Мы помогаем в вопросах трудовых споров, увольнений, заработной платы и других трудовых прав.',
    civilLaw: 'Гражданское право: Охватываем широкий спектр гражданских дел различной сложности.',

    personalDataLaw: 'Закон о защите персональных данных',
    personalDataLawText: 'Нарушение конфиденциальности в наши дни является бичом нашего времени. Защита существует, если знаешь, где её найти.',
    personalRehabilitationLaw: 'Закон о реабилитации физических лиц и предприятий:',
    personalRehabilitationLawText: 'Сверхзадолженность является проблемой номер один и вызывает неуверенность. Однако, при правильном плане и руководстве всегда есть другой вариант.',
    taxLaw: 'Налоговое право:',
    taxLawText: 'Отношения между государством и гражданами в Греции, как все признают, враждебные. Только с помощью специализации и правильного руководства можно избежать непосильных штрафов и надбавок от налоговых органов.',
    realEstateLaw: 'Закон о недвижимости:',
    realEstateLawText: 'Аренда, передача, вопросы градостроительства, распределение имущества между наследниками — это некоторые из повседневных вопросов, которые волнуют владельца недвижимости в Греции. Правильное и разумное управление имуществом — единственный вариант.',
    enforcementLaw: 'Закон о принудительном исполнении и предотвращении аукционов.',
    enforcementLawText: 'Своевременное и в строгие сроки обжалование процедур аукциона является лучшим переговорным оружием для ваших будущих переговоров с каждым кредитором.',

    expropriationLaw: 'Закон об обязательном изъятии.',
    expropriationLawText: 'Вы потеряли своё имущество из-за государства и не получили компенсацию? Мы берем на себя весь процесс выплаты или даже освобождения вашего имущества, чтобы вы могли использовать его так, как хотите.',
    trafficAccidentsLaw: 'Дорожные происшествия:',
    trafficAccidentsLawText: 'Взаимодействие с страховыми компаниями — сложное дело. Получите компенсацию, на которую вы действительно имеете право, без сокращений или мелкого шрифта в страховых полисах.',
    medicalMalpractice: 'Медицинские ошибки',
    medicalMalpracticeText: 'и упущения системы здравоохранения.',
    subsidyReturns: 'Возврат субсидий по инвестиционному закону и ЕСФ:',
    subsidyReturnsText: 'Государство часто требует возврата всей инвестиции за незначительные нарушения. Мы решаем этот вопрос как в административном порядке, так и с помощью обращений в компетентные суды, чтобы предотвратить эту несправедливость в отношении вас.',
    chatbotServices: 'Услуги через Chatbot:',
    chatbotServicesText: 'Наш умный чат-бот готов ответить на все ваши юридические вопросы на любом языке. Просто введите ваше сообщение на предпочитаемом языке. Эта услуга платная, за символическую плату для покрытия затрат на обслуживание огромной базы данных юридической информации.',
    onlineLawyerAppointment: 'Онлайн встреча с адвокатом:',
    onlineLawyerAppointmentText: 'Запишитесь на прием к квалифицированному адвокату в течение следующих 24 часов и получите необходимую поддержку. Наши услуги платные.',

    howWeCanHelp: 'Как мы можем вам помочь:',
    easyAccess: 'Легкий доступ:',
    easyAccessText: 'С помощью нашей онлайн-платформы вы можете легко и быстро записаться на прием, получить консультацию и поговорить с адвокатом.',
    immediateResponse: 'Немедленный отклик:',
    immediateResponseText: 'Мы здесь, чтобы предложить вам немедленную помощь, когда она вам нужна, без задержек.',
    expertiseExperience: 'Экспертиза и опыт:',
    expertiseExperienceText: 'Наша команда состоит из опытных адвокатов, специализирующихся в различных областях права, обеспечивая наилучшую поддержку.',
    affordableServices: 'Доступные услуги:',
    affordableServicesText: 'Мы предлагаем доступные решения, чтобы обеспечить юридическую поддержку всем гражданам 24/7.',
    contactUs: 'Свяжитесь с нами:',
    contactUsText: 'Не позволяйте юридическим проблемам мучить вас. Свяжитесь с нами сегодня, и позвольте нам помочь вам найти нужное решение. Мы здесь для вас в любое время дня, каждый день недели.',
    dikigorosOnline: 'DIKIGOROS ONLINE - Юридические услуги везде и всегда',

    instructionsTitle: 'Инструкции',
    instructionsText1: 'На нашем сайте вы можете воспользоваться онлайн консультацией с виртуальным помощником. Для этого на главной странице нажмите кнопку "Онлайн чат с адвокатом". Необходимо будет оплатить подписку, которая будет действовать 24 часа.',
    instructionsText2: 'Если у вас сложный вопрос, вы можете воспользоваться кнопкой "Booking 24". Наши адвокаты рассмотрят его и в течение суток свяжутся с вами.',
    onlineChatText1: 'Онлайн',
    onlineChatText1_1: 'Чат с юристом',
    onlineChatText2: 'На нашем сайте вы можете воспользоваться онлайн-консультацией с виртуальным помощником. Для этого нажмите кнопку «Онлайн-чат юриста». Вам нужно будет оплатить подписку, которая будет действовать 24 часа.',

    menuButtonBooking: 'Бронирование 24/7',
    menuItemServices: 'Услуги',

    whoWeAre: 'Кто мы',
    welcomeMessage: 'Добро пожаловать в DIKIGOROS ONLINE',
    introductionText: 'В DIKIGOROS ONLINE мы революционизируем предоставление юридических услуг, предлагая легкий и быстрый доступ к квалифицированным юристам через интернет. Мы преданы тому, чтобы предоставлять гражданам нашей страны надежную юридическую поддержку в любое время, когда это необходимо.',
    serviceDescription: 'Мы предлагаем быстрый и обоснованный ответ, который вам нужен, даже до того, как вы поручите свое дело одному из наших опытных юристов, с помощью нашей инновационной онлайн-услуги Chatbot.',
    callToAction: 'Не позволяйте своим юридическим проблемам вас беспокоить. Свяжитесь с нами сегодня, и мы поможем вам найти решение, которое вам нужно. Мы здесь для вас в любое время дня и ночи, каждый день недели.',
    closingText: 'DIKIGOROS ONLINE — юридические услуги везде и всегда.',

    legalAdviceTitle: 'Юридические консультации 24/7',
    legalAdviceText: 'Наши юристы доступны круглосуточно, чтобы ответить на любые ваши юридические вопросы, от срочных дел до повседневных правовых вопросов.',

    familyLawTitle: 'Семейное право',
    familyLawText: 'Мы предоставляем поддержку по вопросам разводов, опеки над детьми, алиментов и другим семейным делам.',

    criminalLawTitle: 'Уголовное право',
    criminalLawText: 'Специализированная защита и консультации по уголовным делам, обеспечивая защиту ваших прав.',

    laborLawTitle: 'Трудовое право',
    laborLawText: 'Помощь в вопросах трудовых споров, увольнений, оплаты труда и других трудовых прав.',

    civilLawTitle: 'Гражданское право',
    civilLawText: 'Мы охватываем широкий спектр гражданских дел, от мелких до более сложных.',




    servicesTitle: 'УСЛУГИ',
    ourServicesTitle: 'НАШИ УСЛУГИ',
    dataProcessingConsent: 'Я даю свое согласие на обработку персональных данных',


    otherServices: 'Другие услуги',

    advantage: 'ПРЕИМУЩЕСТВА',
    howCanHelp: 'КАК МЫ МОЖЕМ ПОМОЧЬ',
    easyAccessDescription: 'С помощью нашей онлайн-платформы вы можете легко и быстро записаться на прием, получить консультации и пообщаться с юристом.',

    immediateResponseDescription: 'Мы здесь, чтобы предоставить вам немедленную помощь, когда бы она вам ни понадобилась, без задержек.',

    expertiseExperienceDescription: 'Наша команда состоит из опытных юристов, специализирующихся в различных областях права, обеспечивая лучшую возможную поддержку.',

    affordableServicesDescription: 'Мы предлагаем экономически доступные решения, чтобы обеспечить юридическую поддержку для всех граждан 24/7.',



    continue: 'Продолжить',
    consentForDataProcessing: 'Я даю согласие на обработку персональных данных',



    bookAppointment: 'ЗАПИСАТЬСЯ НА ПРИЁМ',



    onlineLawyerDescription: 'На нашем сайте вы можете воспользоваться онлайн консультацией с виртуальным помощником. Для этого нажмите на кнопку "Онлайн Чат с Юристом". Вам потребуется оплатить подписку, которая будет действовать 24 часа.',
    onlineLawyerButton: 'Онлайн Чат с Юристом',
    booking24Description: 'Если у вас сложная ситуация, вы можете использовать кнопку "Бронирование 24". Наши юристы изучат ваш вопрос и свяжутся с вами в течение 24 часов.',
    booking24Button: 'Бронирование 24/7',
    learnMore: 'Узнать больше',

    services: 'УСЛУГИ',
    whatTheySay: 'Что они говорят',
    comments: [
      {
        name: 'Георгия Карамолегку',
        text: 'Отличный профессионал с образованием, опытом и моральными принципами. Неоспоримый дар человечности и честности. Надежный, последовательный и чрезвычайно преданный делу, которое он вел от нашего имени. Это было безупречное сотрудничество. В заключение хочу выразить БОЛЬШОЕ СПАСИБО и пожелать продолжать заниматься адвокатской деятельностью с тем же уважением.'
      },
      {
        name: 'НИКОЛАОС МЕКСАС',
        text: 'ОТЛИЧНЫЙ ЧЕЛОВЕК И ВЫДАЮЩИЙСЯ УЧЕНЫЙ. Для меня он в первую очередь человек с этическими принципами и достоинством. Очень добрый, улыбчивый, и с ним чувствуешь себя комфортно с первой минуты. Как адвокат, он выдающийся специалист с глубокими юридическими знаниями и уважением к своему клиенту. Внимательно слушает и отвечает на все вопросы. Рекомендую его без сомнений.'
      },
      {
        name: 'СОТИРИС ТРИВЕЛЛАС',
        text: 'По моему личному мнению, после многих лет сотрудничества, он просто способен на всё. Великолепный образец этики и честности!!!'
      }
    ],



    service_legalAdvice: 'Юридические консультации 24/7',
    service_legalAdviceDesc: 'Наши юристы доступны круглосуточно, чтобы ответить на все ваши юридические вопросы, от срочных проблем до повседневных юридических забот.',

    service_familyLaw: 'Семейное право',
    service_familyLawDesc: 'Мы предоставляем поддержку по вопросам разводов, опеки над детьми, алиментов и других семейных дел.',

    service_criminalLaw: 'Уголовное право',
    service_criminalLawDesc: 'Специализированная защита и консультирование по уголовным делам, гарантируя защиту ваших прав.',

    service_laborLaw: 'Трудовое право',
    service_laborLawDesc: 'Мы помогаем в вопросах трудовых споров, увольнений, заработной платы и других трудовых прав.',

    service_civilLaw: 'Гражданское право',
    service_civilLawDesc: 'Мы охватываем широкий спектр гражданских дел, от простых до сложных.',

    service_personalDataLaw: 'Закон о персональных данных',
    service_personalDataLawDesc: 'Нарушение частной жизни человека в наше время является бедствием. Защита существует, если вы знаете, как ее найти.',

    service_insolvencyLaw: 'Закон о банкротстве',
    service_insolvencyLawDesc: 'Переобремененность является номером один по количеству проблем, вызывая неопределенность. Однако с правильным планом и направлением всегда есть другой вариант.',

    service_taxLaw: 'Налоговое право',
    service_taxLawDesc: 'Отношения между государством и гражданином в Греции, как все признают, враждебные. Только с помощью экспертизы и правильного направления вы можете избежать непосильных штрафов и увеличений от налоговых органов.',

    service_realEstateLaw: "Недвижимость и земельное право",
    service_realEstateLawDesc: "Описание услуг в области недвижимости и земельного права.",
    service_enforcementLaw: "Исполнительное право",
    service_enforcementLawDesc: "Описание услуг в области исполнительного права.",
    service_compulsoryExpropriation: "Принудительное отчуждение",
    service_compulsoryExpropriationDesc: "Вы потеряли свою собственность из-за государства и не получили компенсацию? Мы занимаемся всей процессией выплат или даже освобождением вашей собственности для использования по вашему усмотрению.",
    service_trafficAccidents: "Дорожно-транспортные происшествия",
    service_trafficAccidentsDesc: "Сложный случай, связанный с вашими взаимодействиями с страховыми компаниями. Потребуйте компенсацию, которую вы действительно заслуживаете, без сокращений или мелкого шрифта в страховых полисах.",
    service_medicalErrors: "Медицинские ошибки",
    service_medicalErrorsDesc: "Своевременное вмешательство и обжалование процессов взыскания - ваш лучший инструмент для переговоров с кредиторами в будущем.",
    service_subsidyReturns: "Возврат субсидий по инвестиционному праву и ЕСФ",
    service_subsidyReturnsDesc: "Часто государство требует возврата всей инвестиции за незначительные нарушения. Мы справляемся с этой ситуацией как административно, так и через апелляции в компетентные суды, чтобы предотвратить эту несправедливость по отношению к вам.",
    service_onlineLawyerChat: "Онлайн-чат с юристом",
    service_onlineLawyerChatDesc: "На нашем сайте вы можете воспользоваться онлайн-консультацией с виртуальным помощником. Для этого нажмите кнопку 'Онлайн-чат с юристом'. Будет взиматься абонентская плата, действительная в течение 24 часов.",
    service_onlineLawyerChatButton: "Онлайн-чат с юристом",
    service_booking24: "Бронирование 24/7",
    service_booking24Desc: "Если у вас есть сложная проблема, вы можете использовать кнопку 'Бронирование 24/7'. Наши юристы рассмотрят это и свяжутся с вами в течение 24 часов.",
    service_booking24Button: "Бронирование 24/7",

    instruction_howWeCanHelp: "Как мы можем вам помочь",
    instruction_howChatBotWorks: "Как работает чат-бот",
    instruction_chatBotCost: "Сколько стоит чат-бот",
    instruction_chatBotLanguagesAndAnswers: "На каких языках говорит чат-бот и может давать ответы",
    instruction_whatIfNoResponseFromChatBot: "Что делать, если чат-бот не отвечает",
    instruction_howMuchChatBotCanHelp: "Насколько может помочь чат-бот",
    instruction_whySendEmailAfterPayment: "Почему отправляются имя пользователя и пароль на e-mail после оплаты",
    instruction_whatIsBooking247: "Что такое booking 24/7",
    instruction_booking247Cost: "Сколько стоит услуга booking 24/7?",
    instruction_whyTrustUs: "Почему стоит довериться нам",

    modal_appointmentTitle: "Записаться на прием",
    modal_emailPlaceholder: "Электронная почта",
    modal_phonePlaceholder: "Телефон",
    modal_namePlaceholder: "Имя",
    modal_surnamePlaceholder: "Фамилия",
    modal_questionPlaceholder: "Вопрос",
    modal_continueButton: "Продолжить",
    modal_consentText: "Я даю свое согласие на обработку персональных данных",

    lawyers_nameSurnamePlaceholder: "Имя и фамилия",
    lawyers_professionalAddressPlaceholder: "Профессиональный адрес",
    lawyers_registrationNumberPlaceholder: "Регистрационный номер ассоциации",
    lawyers_associationPlaceholder: "Ассоциация",
    lawyers_emailPlaceholder: "Электронная почта",
    lawyers_phonePlaceholder: "Телефон",
    lawyers_specializationPlaceholder: "Специализация в правовых областях",
    lawyers_experiencePlaceholder: "Годы опыта",
    lawyers_continueButton: "Продолжить",
    lawyers_consentText: "Я даю согласие на обработку персональных данных"


  },
  English: {
    home: 'Home',
    aboutUs: 'About Us',
    instructions: 'Instructions',
    forLawyers: 'For Lawyers',
    forLawyersSubText: "If you want to join our team, fill in the details in the form below. If you meet the requirements, we will contact you to join our team.",
    profile: 'Profile',
    authorization: 'Authorization',
    registration: 'Registration',
    mainText: 'The pursuit of justice',
    mainSubtext: 'Our experience, your peace of mind',
    mainServicesSubtext: `Μην αφήνετε τα νομικά σας προβλήματα να σας ταλαιπωρούν. Επικοινωνήστε μαζί μας 
    σήμερα και επιτρέψτε μας να σας βοηθήσουμε να βρείτε τη λύση που χρειάζεστε.`,
    mainServicesText: 'Services',


    mainInstructionText: `HOW TO GET A CONSULTATION`,
    mainInstructionSubText: `Instructions for obtaining legal advice and assistance online.`,

    onlineLawyerChat: 'Online lawyer chat',
    booking24: 'Booking 24',
    password: 'Password',
    login: 'Login',
    welcome: 'Welcome to DIKIGOROS ONLINE - Legal Services Everywhere and Always. 24/7 close to you and your problems.',
    introText: 'At DIKIGOROS ONLINE, we revolutionize the provision of legal services, offering easy and immediate access to specialized lawyers online. We are dedicated to providing our citizens with reliable legal support whenever they need it. We offer the immediate and well-documented response you seek, even before you assign your CASE to one of our specialized lawyers, through our innovative online service Chatbot.',
    ourServices: 'Our Services:',
    legalAdvice: 'Legal Advice 24/7: Our lawyers are available around the clock to answer all your legal questions, from urgent matters to everyday legal concerns.',
    familyLaw: 'Family Law: We provide support in matters of divorce, child custody, alimony, and other family issues.',
    criminalLaw: 'Criminal Law: Specialized defense and consulting in criminal cases, ensuring your rights are protected.',
    laborLaw: 'Labor Law: We help with issues of labor disputes, dismissals, wages, and other labor rights.',
    civilLaw: 'Civil Law: Covering a wide range of civil cases, from minor to medium and graduated difficulty.',
    personalDataLaw: 'Personal Data Law',
    personalDataLawText: 'Violation of individual privacy is the scourge of our time. Protection exists if you know where to find it.',
    personalRehabilitationLaw: 'Law on Rehabilitation of Individuals and Businesses:',
    personalRehabilitationLawText: 'Over-indebtedness is the number one issue and causes uncertainty. However, with the right plan and direction, there is always another option.',
    taxLaw: 'Tax Law:',
    taxLawText: 'The relationship between the state and citizens in Greece is hostile, as everyone admits. Only with expertise and proper guidance can you avoid unbearable fines and surcharges from tax authorities.',
    realEstateLaw: 'Real Estate Law:',
    realEstateLawText: 'Leases, transfers, urban planning issues, distribution of property among heirs are some of the daily issues that concern property owners in Greece. Methods and plans for proper and prudent property management are the only option.',
    enforcementLaw: 'Law on Compulsory Execution and Prevention of Auctions.',
    enforcementLawText: 'Timely and within strict time limits, contesting auction procedures is the best negotiation weapon for your future discussions with any creditor.',

    expropriationLaw: 'Law on Compulsory Expropriations.',
    expropriationLawText: 'Have you lost your property to the state and not been compensated? We undertake the entire process of your payment or even the release of your property so you can use it as you wish.',
    trafficAccidentsLaw: 'Traffic Accidents:',
    trafficAccidentsLawText: 'Dealing with insurance companies is a difficult case. Get the compensation you truly deserve, without cuts or the fine print of insurance policies.',
    medicalMalpractice: 'Medical Malpractice',
    medicalMalpracticeText: 'and omissions of the healthcare system.',
    subsidyReturns: 'Returns of Subsidies from Investment Law and ESPA:',
    subsidyReturnsText: 'The state often demands the return of the entire investment for minor irregularities. We handle the situation both administratively and by appealing to the competent courts to prevent this injustice against you.',
    chatbotServices: 'Services via Chatbot:',
    chatbotServicesText: 'Our smart chatbot is available to answer all your legal questions in any language. Just type your message in the language you prefer. This service is paid for a symbolic fee to cover the cost of maintaining a huge legal database.',
    onlineLawyerAppointment: 'Online Lawyer Appointment:',
    onlineLawyerAppointmentText: 'Book your appointment with a specialized lawyer within the next 24 hours and receive the support you need. Our services are paid.',

    howWeCanHelp: 'How We Can Help:',
    easyAccess: 'Easy Access:',
    easyAccessText: 'With our online platform, you can easily and quickly book appointments, receive advice, and chat with a lawyer.',
    immediateResponse: 'Immediate Response:',
    immediateResponseText: 'We are here to provide you with immediate assistance whenever you need it, without delays.',
    expertiseExperience: 'Expertise and Experience:',
    expertiseExperienceText: 'Our team consists of experienced lawyers specializing in various areas of law, ensuring the best possible support.',
    affordableServices: 'Affordable Services:',
    affordableServicesText: 'We offer affordable solutions to ensure that legal support is accessible to all citizens 24/7.',
    contactUs: 'Contact Us:',
    contactUsText: 'Don’t let legal problems trouble you. Contact us today and let us help you find the solution you need. We are here for you, any time of the day, every day of the week.',
    dikigorosOnline: 'DIKIGOROS ONLINE - Legal Services Everywhere and Always',

    instructionsTitle: 'Instructions',
    instructionsText1: 'On our website, you can use the online consultation with a virtual assistant. To do this, on the main page, click the "Online lawyer chat" button. You will need to pay a subscription, which will be valid for 24 hours.',
    instructionsText2: 'If you have a complex issue, you can use the "Booking 24" button. Our lawyers will review it and get back to you within 24 hours.',

    onlineChatText1: 'Online ',
    onlineChatText1_1: 'Lawyer Chat',
    onlineChatText2: 'On our website you can use the online consultation with the virtual assistant. For this click on the "Online Lawyer Chat" button. You will need to pay a subscription, which will be valid for 24 hours.',

    menuButtonBooking: 'Booking 24/7',
    menuItemServices: 'SERVICES',
    whoWeAre: 'Who we are',
    welcomeMessage: 'Welcome to DIKIGOROS ONLINE',
    introductionText: 'At DIKIGOROS ONLINE, we are revolutionizing the provision of legal services, offering easy and immediate access to specialized lawyers through the internet. We are dedicated to providing the citizens of our country with reliable legal support, anytime they need it.',
    serviceDescription: 'We offer the immediate and documented response you are looking for, even before assigning the case to one of our specialized lawyers, through our innovative online Chatbot service.',
    callToAction: 'Don\'t let your legal problems trouble you. Contact us today, and let us help you find the solution you need. We are here for you, every moment of the day, every day of the week.',
    closingText: 'DIKIGOROS ONLINE - Legal Services Everywhere and Always.',

    legalAdviceTitle: 'Legal Advice 24/7',
    legalAdviceText: 'Our lawyers are available 24/7 to answer all your legal questions, from urgent matters to everyday legal concerns.',

    familyLawTitle: 'Family Law',
    familyLawText: 'We provide support on divorce, child custody, alimony, and other family matters.',

    criminalLawTitle: 'Criminal Law',
    criminalLawText: 'Specialized defense and counseling in criminal cases, ensuring your rights are protected.',

    laborLawTitle: 'Labor Law',
    laborLawText: 'We assist with labor disputes, dismissals, wages, and other labor rights.',

    civilLawTitle: 'Civil Law',
    civilLawText: 'We cover a broad range of civil cases, from minor to more complex issues.',




    servicesTitle: 'SERVICES',
    ourServicesTitle: 'OUR SERVICES',


    dataProcessingConsent: 'I give my consent for the processing of personal data',


    otherServices: 'Other services',

    advantage: 'ADVANTAGE',
    howCanHelp: 'HOW WE CAN HELP',
    easyAccessDescription: 'With our online platform, you can easily and quickly make appointments, receive advice, and chat with a lawyer.',

    immediateResponseDescription: 'We are here to provide you with immediate assistance whenever you need it, without delays.',

    expertiseExperienceDescription: 'Our team consists of experienced lawyers specializing in various areas of law, ensuring the best possible support.',

    affordableServicesDescription: 'We offer affordable solutions to ensure that legal support is accessible to all citizens 24/7.',



    continue: 'Continue',
    consentForDataProcessing: 'I consent to the processing of personal data',



    bookAppointment: 'BOOK AN APPOINTMENT',


    onlineLawyerDescription: 'On our website, you can use online consultation with the virtual assistant. To do so, click the "Online Lawyer Chat" button. You will need to pay a subscription that will be valid for 24 hours.',
    onlineLawyerButton: 'Online Lawyer Chat',

    booking24Description: 'If you have a difficult issue, you can use the "Booking 24" button. Our lawyers will review it and get back to you within 24 hours.',
    booking24Button: 'Booking 24/7',
    learnMore: 'Learn more',

    services: 'SERVICES',
    whatTheySay: 'What they say',
    comments: [
      {
        name: 'Georgia Karamolegkou',
        text: 'Excellent professional with training, experience, and integrity. Undisputed gift of humanity and honesty. Reliable, consistent, and extremely dedicated to the case he had undertaken on our behalf. It was a flawless collaboration. In conclusion, what I want to express is a big THANK YOU and keep practicing law with the same respect.'
      },
      {
        name: 'NIKOLAOS MEXAS',
        text: 'EXCELLENT PERSON AND EXCELLENT SCIENTIST. For me, he is first and foremost a person with ethics and dignity. He is very kind, smiling, and you feel comfortable from the first minute. As a lawyer, he is an excellent scientist, with high legal knowledge and respect for his client. He listens carefully and answers all questions. I highly recommend him.'
      },
      {
        name: 'SOTIRIS TRIVELLAS',
        text: 'In my personal opinion, after many years of collaboration, simply capable of everything. A giant of ethics and honesty!!!'
      }
    ],

    service_legalAdvice: 'Legal Advice 24/7',
    service_legalAdviceDesc: 'Our lawyers are available 24/7 to answer all your legal questions, from urgent issues to everyday legal concerns.',

    service_familyLaw: 'Family Law',
    service_familyLawDesc: 'We provide support in divorce, child custody, alimony, and other family law matters.',

    service_realEstateLaw: "Real Estate Law",
    service_realEstateLawDesc: "Description for Real Estate Law services.",
    service_enforcementLaw: "Enforcement Law",
    service_enforcementLawDesc: "Description for Enforcement Law services.",
    service_compulsoryExpropriation: "Compulsory Expropriation",
    service_compulsoryExpropriationDesc: "Have you lost your property to the state and have not been compensated? We handle the entire payment process or even the release of your property for you to utilize as you wish.",
    service_trafficAccidents: "Traffic Accidents",
    service_trafficAccidentsDesc: "A difficult case involving your dealings with insurance companies. Claim the compensation you truly deserve, without cuts or the fine print of insurance policies.",
    service_medicalErrors: "Medical Errors",
    service_medicalErrorsDesc: "Timely intervention and appeal against foreclosure processes is your best negotiating tool for future discussions with your creditors.",
    service_subsidyReturns: "Subsidy Returns from Investment Law and ESF",
    service_subsidyReturnsDesc: "Often, the state demands the return of the entire investment for minor irregularities. We handle this situation both administratively and through appeals to the competent courts to prevent this injustice against you.",
    service_onlineLawyerChat: "Online Lawyer Chat",
    service_onlineLawyerChatDesc: "On our website, you can use the online consultation with the virtual assistant. For this, click the 'Online Lawyer Chat' button. A subscription fee will apply, which will be valid for 24 hours.",
    service_onlineLawyerChatButton: "Online Lawyer Chat",
    service_booking24: "Booking 24",
    service_booking24Desc: "If you have a difficult issue, you can use the 'Booking 24' button. Our lawyers will review it and contact you within 24 hours.",
    service_booking24Button: "Booking 24/7",
    service_criminalLaw: 'Criminal Law',
    service_criminalLawDesc: 'Specialized defense and consultation in criminal cases, ensuring the protection of your rights.',

    service_laborLaw: 'Labor Law',
    service_laborLawDesc: 'We assist with labor disputes, terminations, wages, and other labor rights.',

    service_civilLaw: 'Civil Law',
    service_civilLawDesc: 'We cover a wide range of civil cases, from simple to complex.',

    service_personalDataLaw: 'Data Protection Law',
    service_personalDataLawDesc: 'Infringement of privacy is a calamity in our time. Protection exists if you know how to find it.',

    service_insolvencyLaw: 'Bankruptcy Law',
    service_insolvencyLawDesc: 'Overburdening is number one in terms of problems causing uncertainty. However, with the right plan and guidance, there is always another option.',

    service_taxLaw: 'Tax Law',
    service_taxLawDesc: 'The relationship between the state and the citizen in Greece is, as everyone recognizes, hostile. Only with expertise and the right guidance can you avoid burdensome fines and increases from tax authorities.',

    instruction_howWeCanHelp: "How can we help you",
    instruction_howChatBotWorks: "How does the chat bot work",
    instruction_chatBotCost: "What is the cost of the chat bot",
    instruction_chatBotLanguagesAndAnswers: "In which languages does the chat bot speak and provide answers",
    instruction_whatIfNoResponseFromChatBot: "What do I do if the chat bot doesn't respond",
    instruction_howMuchChatBotCanHelp: "How much can the chat bot help",
    instruction_whySendEmailAfterPayment: "Why are the username and password sent to the email after payment",
    instruction_whatIsBooking247: "What is booking 24/7",
    instruction_booking247Cost: "What is the cost of booking 24/7 service?",
    instruction_whyTrustUs: "Why trust us",


    modal_appointmentTitle: "Schedule an Appointment",
    modal_emailPlaceholder: "Email",
    modal_phonePlaceholder: "Phone",
    modal_namePlaceholder: "Name",
    modal_surnamePlaceholder: "Surname",
    modal_questionPlaceholder: "Question",
    modal_continueButton: "Continue",
    modal_consentText: "I consent to the processing of personal data",

    lawyers_nameSurnamePlaceholder: "Name and Surname",
    lawyers_professionalAddressPlaceholder: "Professional Address",
    lawyers_registrationNumberPlaceholder: "Association Registration Number",
    lawyers_associationPlaceholder: "Association",
    lawyers_emailPlaceholder: "Email",
    lawyers_phonePlaceholder: "Phone",
    lawyers_specializationPlaceholder: "Specialization in Legal Fields",
    lawyers_experiencePlaceholder: "Years of Experience",
    lawyers_continueButton: "Continue",
    lawyers_consentText: "I give my consent for the processing of personal data"

  }
};
