import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo_menu from '../../static/img/logo_menu.png';

import { svg_out, svg_burger } from '../../static/svg';



import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../home/languageSelector';
import { selectEmail, selectLanguage, setEmail, setShowModal } from '../home/homeSlice';
import { translations } from '../home/translation';

import '../home/home.css'
import './navMenu.css'
import BtnChat from '../buttons/btnChat';

const NavMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const language = useSelector(selectLanguage)
    const email = useSelector(selectEmail)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const goToServicesPage = () => navigate('/services');

    const goToInstructionsPage = () => navigate('/instructions');

    const goToLawyersPage = () => navigate('/lawyers');

    const handleMenuMainClick = () => navigate('/');

    const goToWhoWe = () => navigate('/whowe')

    const handleClickBooking = () => {
        dispatch(setShowModal(true))
    };

    return (
        <>
            {/* Десктопная навигация */}
            <nav className="nav-menu" style={{ position: 'absolute', top: "0px", zIndex: 1 }}>
                <div style={{ width: '64px', marginTop: '21px' }}>
                    <img src={logo_menu} alt="Logo" className="logo_menu nav-item" />
                </div>
                <div style={{ marginTop: '41px' }} className='nav-item'>
                    <LanguageSelector />
                </div>

                <div className='cntr_menu'>
                    <span className="nav-item" onClick={handleMenuMainClick}>
                        {translations[language].home}
                    </span>
                    <span className="nav-item" onClick={goToWhoWe}>
                        {translations[language].aboutUs}
                    </span>
                    <span className="nav-item" onClick={goToServicesPage}>
                        {translations[language].menuItemServices}
                    </span>
                    <span className="nav-item" onClick={goToInstructionsPage}>
                        {translations[language].instructions}
                    </span>
                    <span className="nav-item" onClick={goToLawyersPage}>
                        {translations[language].forLawyers}
                    </span>
                </div>

                <div className='nav-item txt_cap_under' style={{ marginTop: '31px', marginLeft: '270px' }}>
                    {translations[language].menuButtonBooking}
                </div>

                {email !== '' && (
                    <div onClick={() => dispatch(setEmail(''))} className='svg_out'>
                        {svg_out}
                    </div>
                )}
            </nav>

            {/* Мобильная навигация */}
            <nav className="nav-menu-mobile" style={{ left: '50%', transform: 'translateX(-50%)', position: 'absolute', top: "20px", height: '0px', zIndex: 2 }}>
                <div style={{ width: '150px' }}>
                    <img src={logo_menu} alt="Logo" className="logo nav-item" />
                </div>



                <span className="burger-menu-home" onClick={() => setIsMenuOpen(!isMenuOpen)}>

                    {svg_burger}
                </span>
                <div className='nav-item' style={{}}>
                    <LanguageSelector />
                </div>
                <div className='nav-item txt_cap_under' style={{ width: 'fit-content', position: 'absolute', left: '140px', top: '21px' }}
                    onClick={handleClickBooking}
                >
                    {translations[language].menuButtonBooking}
                </div>


            </nav>
            {isMenuOpen && (
                <>

                    <div className='bg_mobile_menu'>

                    </div>
                    <div className="mobile-menu">
                        <div className='mob_menu_header'>
                            <img src={logo_menu} alt="Logo" className="logo nav-item" />
                            <div className='mob_menu_cross' onClick={() => setIsMenuOpen(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                    <path d="M0.808206 21.2346L22.0214 0.0214091L23.1918 1.19179L1.97859 22.405L0.808206 21.2346ZM0.808207 1.19179L1.97859 0.0214091L23.1918 21.2346L22.0214 22.405L0.808207 1.19179Z" fill="#F5F5F5" />
                                </svg>
                            </div>
                        </div>
                        <div className='mob_menu_cntr_items'>
                            <span className="mob_menu_nav-item" onClick={handleMenuMainClick}>{translations[language].home}</span>
                            <span className="mob_menu_nav-item" onClick={goToWhoWe}>{translations[language].aboutUs}</span>
                            <span className="mob_menu_nav-item" onClick={goToInstructionsPage}>{translations[language].instructions}</span>
                            <span className="mob_menu_nav-item" onClick={goToLawyersPage}>{translations[language].forLawyers}</span>
                            <div className='mob_menu_nav-item txt_cap_under'
                                onClick={handleClickBooking}
                            >
                                Κράτηση 24/7
                            </div>
                        </div>
                        <div className='mob_menu_cntr_btn'>
                            <BtnChat />
                            <div style={{ width: '180px' }} className="btn_booling_txt btn_booking_page" onClick={() => { }}>
                                {translations[language].booking24}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default NavMenu;
