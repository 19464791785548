import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectEmail, selectLanguage, selectStatus, setStatus } from '../home/homeSlice';
import { translations } from '../home/translation';
import './btn.css'
import { getPayLink } from '../profile/apiProfile';

const BtnChat = ({start}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const language = useSelector(selectLanguage)
    const email = useSelector(selectEmail)
    const status = useSelector(selectStatus)

    const handleBuySubscription = async () => {
        console.log('handleBuySubscription')
        const paylink = await getPayLink(email)
        dispatch(setStatus('subscribe'))
        console.log('paylink', paylink)
        window.open(paylink.paymentLink, '_blank', 'noopener,noreferrer');
    };

    // const handleOnlineClick = () => {
    //     console.log('handleOnlineClick', email, status)
    //     if (email === '') setActiveMenu('login')
    //     else if (status !== 'subscribe') handleBuySubscription()
    //     else handleMenuClick('ai')
    // }

    const handleOnlineClick = () => {
        if (start) {
            if (status !== 'subscribe') handleBuySubscription()
            else  navigate('/chat/start');
        }
        else navigate('/chat');
    };

    return (
        <div className="btn_online_txt btn_online_b" onClick={handleOnlineClick}>
            {translations[language].onlineLawyerChat}
        </div>
    );
};

export default BtnChat;
