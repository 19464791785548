import React, { useState } from 'react';
import './footer.css';
import logo_menu from '../../static/img/logo_menu.png';
import { translations } from './translation';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, setShowModal } from './homeSlice';
import { useNavigate } from 'react-router-dom';



const Footer = ({ handleMenuClick }) => {

    const language = useSelector(selectLanguage)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const goToServicesPage = () => navigate('/services');

    const goToInstructionsPage = () => navigate('/instructions');

    const goToLawyersPage = () => navigate('/lawyers');

    const handleMenuMainClick = () => navigate('/');

    const handleClickBooking = () => {
        dispatch(setShowModal(true))
    };


    return (
        <div className='cntr_footer' style={{ marginTop: '140px' }}>
            <div className='cntr_footer_row'>
                <div style={{ width: '64px' }}>
                    <img src={logo_menu} alt="Logo" className="footer_logo" />
                </div>
                <div className='footer_cntr_menu'>

                    <span className="nav-item" onClick={handleMenuMainClick}>{translations[language].home}</span>
                    <span className="nav-item" onClick={() => {}}>{translations[language].aboutUs}</span>
                    <span className="nav-item" onClick={goToServicesPage}>{translations[language].services}</span>

                    <span className="nav-item" onClick={goToInstructionsPage}>{translations[language].instructions}</span>
                    <span className="nav-item" onClick={() => { }}>{translations[language].forLawyers}</span>

                </div>
                <div className='nav-item txt_cap_under' style={{ marginTop: '31px' }} onClick={handleClickBooking}>
                    {translations[language].booking24Button}
                </div>
            </div>

            <div className='cntr_footer_row_mob'>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>

                    <div style={{ width: '64px' }}>
                        <img src={logo_menu} alt="Logo" className="footer_logo" />
                    </div>
                    <div className='nav-item txt_cap_under' style={{ marginTop: '31px' }} onClick={handleClickBooking}>
                        {translations[language].booking24Button}
                    </div>
                </div>
                <div className='footer_cntr_menu'>

                    <span className="nav-item" onClick={handleMenuMainClick}>{translations[language].home}</span>
                    <span className="nav-item" onClick={() => {}}>{translations[language].aboutUs}</span>

                </div>
                <div className='footer_cntr_menu'>

                    <span className="nav-item" onClick={goToInstructionsPage}>{translations[language].instructions}</span>
                    <span className="nav-item" onClick={goToLawyersPage}>{translations[language].forLawyers}</span>

                </div>

            </div>


            <div className='cntr_footer_line' style={{ marginTop: '40px' }}>
                <svg className='svg_line' width="1300" height="1" viewBox="0 0 1300 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="1300" y2="0.5" stroke="#A0A0A0" />
                </svg>
            </div>

            {/* <div className='footer_cntr_links'>
                <div className='footer_link_online'>
                    DIKIGOROS ONLINE
                </div>
                <div className='footer_link_online'>
                    ПРОСОΠΙΚΑ ΔΕΔΟΜΕΝΑ
                </div>
            </div> */}

            <div className='footer_cntr_links'>
                <div className='footer_link_online'>
                    {translations[language].lawyerOnline}
                </div>
                <div className='footer_link_online'>
                    {translations[language].personalData}
                </div>
            </div>

        </div>
    );
};

export default Footer;
