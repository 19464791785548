import { url_back } from "../../static/vars";

async function getPayLink(email) {
    console.log('getPayLink', email)
    try {
        const response = await fetch(url_back + '/paylink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const result = await response.json();
            console.log('result', result);
            
            return result;
        } else {
            console.log('Error sending question');
            const errorData = await response.json();
            console.error('Error message:', errorData.message);
            return false;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return false;
    }
}

export { getPayLink };
