import React, { useState } from 'react';
import './services.css';
import './advantage.css';
import { svg_advantage } from '../../static/svg'
import { useSelector } from 'react-redux';
import { selectLanguage } from './homeSlice';
import { translations } from './translation';

const testimonials = [
    {
        text: 'This is an amazing product!',
        name: 'John Doe',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'I had a great experience with this service.',
        name: 'Jane Smith',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'Highly recommend to everyone!',
        name: 'Alice Johnson',
        avatar: 'https://via.placeholder.com/50'
    }
];

const Advantage = ({ setActiveMenu }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('');
    const [email, setEmailInput] = useState('');
    const [password, setPassword] = useState('');

    const language = useSelector(selectLanguage)

    return (
        // <div className='cntr_advantage' >
        //     <div className="services_title_cntr">
        //         <label className='title1_wellcome'>ΠΛΕΟΝΕΚΤΗΜΑ</label>
        //         <div className='title1_line'></div>
        //         <label className='title1_wellcome2' style={{ marginTop: '20px'}}>
        //             ΠΩΣ ΜΠΟΡΟΥΜΕ ΝΑ<br></br> ΣΑΣ ΒΟΗΘΗΣΟΥΜΕ
        //         </label>
        //     </div>

        //     <div className='cntr_advantage2'>
        //         <div className='advantage_item_cntr'>
        //             <div style={{ position: 'relative' }}>
        //                 {svg_advantage}
        //                 <label className='advantage_text'>ΕΥκολη ΠρΟσβαση</label>
        //             </div>
        //             <label className='advantage_item_text'>Με την διαδικτυακή μας πλατφόρμα, μπορείτε να κλείσετε ραντεβού, να λάβετε συμβουλές και να συνομιλήσετε με δικηγόρο εύκολα και γρήγορα.</label>
        //         </div>
        //         <div className='advantage_item_cntr'>
        //             <div style={{ position: 'relative' }}>
        //                 {svg_advantage}
        //                 <label className='advantage_text'>Αμεση ΑνταπΟκριση</label>
        //             </div>
        //             <label className='advantage_item_text'>Είμαστε εδώ για να σας προσφέρουμε άμεση βοήθεια όποτε την χρειάζεστε, χωρίς καθυστερήσεις.</label>
        //         </div>
        //         <div className='advantage_item_cntr'>
        //             <div style={{ position: 'relative' }}>
        //                 {svg_advantage}
        //                 <label className='advantage_text'>ΕξειδΙκευση και ΕμπειρΙα</label>
        //             </div>
        //             <label className='advantage_item_text'>Η ομάδα μας αποτελείται από έμπειρους δικηγόρους με εξειδίκευση σε διάφορους τομείς του δικαίου, εξασφαλίζοντας την καλύτερη δυνατή υποστήριξη.</label>
        //         </div>
        //         <div className='advantage_item_cntr'>
        //             <div style={{ position: 'relative' }}>
        //                 {svg_advantage}
        //                 <label className='advantage_text'>ΠροσιτΕς ΥπηρεσΙες</label>
        //             </div>
        //             <label className='advantage_item_text'>Προσφέρουμε οικονομικά προσιτές λύσεις για να εξασφαλίσουμε ότι η νομική υποστήριξη είναι προσβάσιμη σε όλους τους πολίτες 24/7.</label>
        //         </div>
        //     </div>
        // </div>

        <div className='cntr_advantage'>
            <div className="services_title_cntr">
                <label className='title1_wellcome'>{translations[language].advantage}</label>
                <div className='title1_line'></div>
                <label className='title1_wellcome2' style={{ marginTop: '20px' }}>
                    {translations[language].howCanHelp}
                </label>
            </div>

            <div className='cntr_advantage2'>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>{translations[language].easyAccess}</label>
                    </div>
                    <label className='advantage_item_text'>{translations[language].easyAccessDescription}</label>
                </div>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>{translations[language].immediateResponse}</label>
                    </div>
                    <label className='advantage_item_text'>{translations[language].immediateResponseDescription}</label>
                </div>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>{translations[language].expertiseExperience}</label>
                    </div>
                    <label className='advantage_item_text'>{translations[language].expertiseExperienceDescription}</label>
                </div>
                <div className='advantage_item_cntr'>
                    <div style={{ position: 'relative' }}>
                        {svg_advantage}
                        <label className='advantage_text'>{translations[language].affordableServices}</label>
                    </div>
                    <label className='advantage_item_text'>{translations[language].affordableServicesDescription}</label>
                </div>
            </div>
        </div>


    );
};

export default Advantage;
