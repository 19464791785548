import React, { useState } from 'react';
import './mainPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmail, selectLanguage, setEmail, setShowModal } from '../home/homeSlice';
import { sendLawyerQuestion } from '../lawyer/apiLawyer';
import Pdf from '../../static/privacypolicy.pdf';
import { getPayLink } from '../profile/apiProfile';
import { translations } from '../home/translation';
import { useNavigate } from 'react-router-dom';

const ModalData = ({ show, handleClose, handleSubmit }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const language = useSelector(selectLanguage)
    const email = useSelector(selectEmail);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [question, setQuestion] = useState('');
    const [consent, setConsent] = useState(false);

    const [isChecked, setIsChecked] = useState(false);

    const handleClickTermsUse = () => {
        navigate('/termsuse')
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleClickPrivacyPolicy = () => {
        window.open(`../../../static/privacypolicy.pdf`, '_blank');
    }

    const handleBuySubscription = async () => {
        // Logic for buying subscription, this is just a placeholder
        const paylink = await getPayLink(email)
        console.log('paylink', paylink)
        window.open(paylink.paymentLink, '_blank', 'noopener,noreferrer');
    };

    const onSubmit = () => {
        // handleSubmit(email, phone, name, surname);
        // sendLawyerQuestion(name, email, phone, question);
        // handleClose();
        handleBuySubscription()
        handleCloseModal()
    };

    if (!show) {
        return null;
    }

    const handleCloseModal = () => {
        console.log('handleCloseModal')
        dispatch(setShowModal(false))
    }

    return (
        // <div className="modal-overlay" onClick={handleCloseModal}>
        //     <div className="modal-content">
        //         <h2>Κλείστε ραντεβού</h2>
        //         <input placeholder='Email' type="email" value={email} onChange={(e) => dispatch(setEmail(e.target.value))} />
        //         <input placeholder='Phone' type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
        //         <input placeholder='Name' type="text" value={name} onChange={(e) => setName(e.target.value)} />
        //         <input placeholder='Surname' type="text" value={surname} onChange={(e) => setSurname(e.target.value)} />
        //         <textarea
        //             value={question}
        //             onChange={(e) => setQuestion(e.target.value)}
        //             placeholder="Question"
        //         />
        //         {/* <div onClick={handleClickPrivacyPolicy} style={{ color: 'black', fontSize: '12px', textAlign: 'left', cursor: 'pointer'}}>
        //             Privacy Policy
        //         </div> */}
        //         {/* <a style={{ color: 'black', fontSize: '12px', textAlign: 'left' }} rel="noreferrer" href={Pdf} target="_blank">Privacy Policy</a> */}
        //         <div className='modal_cntr_btns'>
        //             <button className='modal_btn' onClick={onSubmit}>Συνέχεια</button>
        //             {/* <button onClick={handleClose}>Κλείσιμο</button> */}
        //         </div>

        //         <div className='modal_bottom_text'>
        //             Δίνω τη συγκατάθεσή μου για την επεξεργασία προσωπικών δεδομένων
        //         </div>
        //     </div>
        // </div>
        <div className="modal-overlay" >
            <div className="modal-content">
                <svg onClick={handleCloseModal} className='svg_close' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L36.3553 36.3553" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" />
                    <path d="M1 36.3555L36.3553 1.00013" stroke="#F5F5F5" stroke-width="2" stroke-linecap="round" />
                </svg>

                <h2>{translations[language].modal_appointmentTitle}</h2>
                <input
                    placeholder={translations[language].modal_emailPlaceholder}
                    type="email"
                    value={email}
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                />
                <input
                    placeholder={translations[language].modal_phonePlaceholder}
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <input
                    placeholder={translations[language].modal_namePlaceholder}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    placeholder={translations[language].modal_surnamePlaceholder}
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />
                <textarea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder={translations[language].modal_questionPlaceholder}
                />
                <div className='modal_cntr_btns'>
                    <button className='modal_btn' onClick={onSubmit}>
                        {translations[language].modal_continueButton}
                    </button>
                </div>
                <div className='modal_bottom_cntr' style={{ marginBottom: '20px'}}>
                    <div className='modal_checkbox'>
                        <input
                            className='modal_checkbox'
                            type='checkbox'
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className='modal_bottom_text'>
                        {translations[language].modal_consentText}
                        <span onClick={handleClickTermsUse} style={{textDecoration: 'underline', cursor: 'pointer'}}>{translations[language].modal_consentText2}</span>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default ModalData;
