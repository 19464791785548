import React, { useEffect, useState } from 'react';
import './services.css';

import service_img1 from '../../static/img/services_page/services_page_img1.png';
import service_img2 from '../../static/img/services_page/services_page_img2.png';
import service_img3 from '../../static/img/services_page/services_page_img3.png';
import service_img4 from '../../static/img/services_page/services_page_img4.png';
import service_img5 from '../../static/img/services_page/services_page_img5.png';
import service_img6 from '../../static/img/services_page/services_page_img6.png';
import service_img7 from '../../static/img/services_page/services_page_img7.png';
import service_img8 from '../../static/img/services_page/services_page_img8.png';
import service_img9 from '../../static/img/services_page/services_page_img9.png';
import service_img10 from '../../static/img/services_page/services_page_img10.png';
import service_img11 from '../../static/img/services_page/services_page_img11.png';
import service_img12 from '../../static/img/services_page/services_page_img12.png';
import service_img13 from '../../static/img/services_page/services_page_img13.png';
import service_img14 from '../../static/img/services_page/services_page_img14.png';

import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';
import { translations } from '../home/translation';

const testimonials = [
    {
        text: 'This is an amazing product!',
        name: 'John Doe',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'I had a great experience with this service.',
        name: 'Jane Smith',
        avatar: 'https://via.placeholder.com/50'
    },
    {
        text: 'Highly recommend to everyone!',
        name: 'Alice Johnson',
        avatar: 'https://via.placeholder.com/50'
    }
];

const Services = ({ setActiveMenu }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState('');
    const [email, setEmailInput] = useState('');
    const [password, setPassword] = useState('');

    const language = useSelector(selectLanguage)

    const prevSlide = () => {
        setDirection('left');
        const index = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextSlide = () => {
        setDirection('right');
        const index = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    const handleLogin = () => {
        console.log('handleLogin')
    }

    useEffect(() => {
        setEmailInput('')
    }, []);

    return (
        <div>
            <div className="services_title_cntr">
                {/* <label className='title1_wellcome'>ΥΠΗΡΕΣΙΕΣ</label> */}
                <div className='title1_line' style={{ marginTop: '140px' }}></div>
                <label className='title1_wellcome2'>
                    {translations[language].services}
                </label>
            </div>

            <div className='services_row' style={{ marginTop: '60px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img1} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_legalAdvice}</label>
                    <label className='services_row_item_text2'>{translations[language].service_legalAdviceDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img2} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_familyLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_familyLawDesc}</label>
                </div>
            </div>


            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img3} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_criminalLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_criminalLawDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img4} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_laborLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_laborLawDesc}</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img5} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_civilLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_civilLawDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img6} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_personalDataLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_personalDataLawDesc}</label>
                </div>
            </div>


            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img7} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_insolvencyLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_insolvencyLawDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img8} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_taxLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_taxLawDesc}</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img9} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_realEstateLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_realEstateLawDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img10} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_enforcementLaw}</label>
                    <label className='services_row_item_text2'>{translations[language].service_enforcementLawDesc}</label>
                </div>
            </div>


            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img11} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_compulsoryExpropriation}</label>
                    <label className='services_row_item_text2'>{translations[language].service_compulsoryExpropriationDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img12} alt='' />
                    <label className='services_row_item_text1'>{translations[language].service_trafficAccidents}</label>
                    <label className='services_row_item_text2'>{translations[language].service_trafficAccidentsDesc}</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img13} alt='' />
                    <label className='services_row_item_text1' style={{ top: '142px' }}>{translations[language].service_medicalErrors}</label>
                    <label className='services_row_item_text2' style={{ top: '224px' }}>{translations[language].service_medicalErrorsDesc}</label>
                </div>
                <div style={{ width: '630px', position: 'relative' }}>
                    <img style={{ width: '630px' }} src={service_img14} alt='' />
                    <label className='services_row_item_text1' style={{ top: '142px' }}>{translations[language].service_subsidyReturns}</label>
                    <label className='services_row_item_text2' style={{ top: '224px' }}>{translations[language].service_subsidyReturnsDesc}</label>
                </div>
            </div>

            <div className='services_row' style={{ marginTop: '40px' }}>
                <div style={{ width: '630px', position: 'relative', height: '350px', background: '#333B5F' }}>
                    <label className='services_row_item_text1' style={{ top: '100px' }}>{translations[language].service_onlineLawyerChat}</label>
                    <label className='services_row_item_text2' style={{ top: '151px' }}>{translations[language].service_onlineLawyerChatDesc}</label>
                    <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '263px', marginLeft: '30px' }}>
                        {translations[language].service_onlineLawyerChatButton}
                    </div>
                </div>
                <div style={{ width: '630px', position: 'relative', background: '#333B5F' }}>
                    <label className='services_row_item_text1' style={{ top: '100px' }}>{translations[language].service_booking24}</label>
                    <label className='services_row_item_text2' style={{ top: '151px' }}>{translations[language].service_booking24Desc}</label>
                    <div className='login_widget_button instruction_btn_txt' style={{ width: '220px', marginTop: '263px', marginLeft: '30px' }}>
                        {translations[language].service_booking24Button}
                    </div>
                </div>
            </div>

        </div>

    );
};

export default Services;
