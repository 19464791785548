import React from 'react';
import './aboutUs.css';
import { translations } from '../home/translation';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../home/homeSlice';

const AboutUs = () => {
    const language = useSelector(selectLanguage)
    const t = translations[language]
    return (
        <div className='legal-services'>
            <h3>{t.welcome}</h3>
            <p>{t.introText}</p>

            <h2>{t.ourServices}</h2>
            <div style={{ color: 'black' }}>
                <p>{t.legalAdvice}</p>
                <p>{t.familyLaw}</p>
                <p>{t.criminalLaw}</p>
                <p>{t.laborLaw}</p>
                <p>{t.civilLaw}</p>
            </div>

            {/* <h2>ΔΙΚΑΙΟ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h2>
            <p>Η προσβολή της ιδιωτικότητας του ατόμου στις μέρες μας είναι η μάστιγα της εποχής. Υπάρχει προστασία αρκεί να ξέρεις να την βρεις.</p>
            <h3>ΔΙΚΑΙΟ ΕΞΥΓΙΑΝΣΗΣ ΦΥΣΙΚΩΝ ΠΡΟΣΩΠΩΝ ΚΑΙ ΕΠΙΧΕΙΡΗΣΕΩΝ :</h3>
            <p>Η υπερχρέωση αποτελεί το νούμερο ένα ζήτημα και προκαλεί αβεβαιότητα. Με σωστό πλάνο και κατεύθυνση όμως πάντα υπάρχει και η άλλη επιλογή.</p>
            <h3>ΦΟΡΟΛΟΓΙΚΟ ΔΙΚΑΙΟ :</h3>
            <p>Η σχέση κράτους πολίτη στην Ελλάδα είναι όπως όλοι ομολογούν εχθρική. Μόνο με εξειδίκευση και ορθή κατεύθυνση αποφεύγεις δυσβάσταχτα πρόστιμα και προσαυξήσεις από τις φορολογικές αρχές.</p>
            <h3>ΔΙΚΑΙΟ ΑΚΙΝΗΤΩΝ :</h3>
            <p>Μισθώσεις, μεταβιβάσεις, πολεοδομικά θέματα, διανομή περιουσίας μεταξύ κληρονόμων μερικά από τα καθημερινά ζητήματα που απασχολούν τον ιδιοκτήτη ακινήτου στην Ελλάδα. Τρόποι και πλάνο ορθής και συνετής διαχείρισης περιουσίας η μόνη επιλογή.</p>
            <h3>ΔΙΚΑΙΟ ΑΝΑΓΚΑΣΤΙΚΗΣ ΕΚΤΕΛΕΣΗΣ ΚΑΙ ΑΠΟΤΡΟΠΗΣ ΠΛΕΙΣΤΗΡΙΑΣΜΩΝ.</h3>
            <p>Η έγκαιρη και εντός των αυστηρών χρονικών ορίων ανακοπή των διαδικασιών πλειστηριασμού αποτελεί το καλύτερο διαπραγματευτικό όπλο για μελλοντικές συζητήσεις σας με κάθε πιστωτή σας.</p>
             */}
            <h2>{t.personalDataLaw}</h2>
            <p>{t.personalDataLawText}</p>

            <h3>{t.personalRehabilitationLaw}</h3>
            <p>{t.personalRehabilitationLawText}</p>

            <h3>{t.taxLaw}</h3>
            <p>{t.taxLawText}</p>

            <h3>{t.realEstateLaw}</h3>
            <p>{t.realEstateLawText}</p>

            <h3>{t.enforcementLaw}</h3>
            <p>{t.enforcementLawText}</p>

            <h3>{t.expropriationLaw}</h3>
            <p>{t.expropriationLawText}</p>

            <h3>{t.trafficAccidentsLaw}</h3>
            <p>{t.trafficAccidentsLawText}</p>

            <h3>{t.medicalMalpractice}</h3>
            <p>{t.medicalMalpracticeText}</p>

            <h3>{t.subsidyReturns}</h3>
            <p>{t.subsidyReturnsText}</p>

            <h3>{t.chatbotServices}</h3>
            <p>{t.chatbotServicesText}</p>

            <h3>{t.onlineLawyerAppointment}</h3>
            <p>{t.onlineLawyerAppointmentText}</p>

            <h2>{t.howWeCanHelp}</h2>
            <h3>{t.easyAccess}</h3>
            <p>{t.easyAccessText}</p>
            <h3>{t.immediateResponse}</h3>
            <p>{t.immediateResponseText}</p>
            <h3>{t.expertiseExperience}</h3>
            <p>{t.expertiseExperienceText}</p>
            <h3>{t.affordableServices}</h3>
            <p>{t.affordableServicesText}</p>

            <h2>{t.contactUs}</h2>
            <p>{t.contactUsText}</p>
            <p style={{ color: '#18171e' }}>{t.dikigorosOnline}</p>

        </div>
    );
};

export default AboutUs;
