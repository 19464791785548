// Register.js
import React, { useState } from 'react';
import './login.css';
import { url_back } from '../../static/vars';
import { sendRegister } from './apiLogin';

function Register({ setActiveMenu }) {
  const [email, setEmailInput] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async () => {
    const res_reg = await sendRegister(email, password)
    if (res_reg.ok) {
      alert('Вы зарегистрированы');
      setActiveMenu('login');
    } else {
      alert('Ошибка регистрации');
    }
    // Отправка запроса на бэкэнд для регистрации
    // const response = await fetch(url_back + '/register', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ email, password })
    // });

    // if (response.ok) {
    //   alert('Вы зарегистрированы');
    //   setActiveMenu('Авторизация');
    // } else {
    //   alert('Ошибка регистрации');
    // }
  };

  return (
    <div className="auth-container">
      <h2>Регистрация</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmailInput(e.target.value)}
      />
      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleRegister}>Зарегистрироваться</button>
    </div>
  );
}

export default Register;
